export const FETCH_DATA_FOR_PAGE = 'page/FETCH_DATA_FOR_PAGE'
export const READY_TO_DISPLAY_PAGE = 'page/READY_TO_DISPLAY_PAGE'

export function fetchDataFor(page) {
  return {
    type: FETCH_DATA_FOR_PAGE,
    page
    // page: page.replace(/^\/(list|music)\/*/, '/')
  }
}

export function readyToDisplay(page) {
  return {
    type: READY_TO_DISPLAY_PAGE,
    page
    // page: page.replace(/^\/(list|music)\/*/, '/')
  }
}

const initialState = {}

export default function pagesReducer(state = initialState, action) {
  const { page } = action;
  const newPageState = Object.assign({}, state[page])
  switch(action.type) {
    case FETCH_DATA_FOR_PAGE:
      newPageState.fetched = true;
      return Object.assign({}, state, { [page]: newPageState })
    case READY_TO_DISPLAY_PAGE:
      newPageState.render = true;
      return Object.assign({}, state, { [page]: newPageState })
    default:
      return state
  }
}
