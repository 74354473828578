import React, { Component } from 'react'
import isNumber from 'lodash/isNumber'
import { fancyTimeFormat } from '../../lib/utils'
import style from './ProgressBar.scss'

export default class ProgressBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cursor: false
    }

    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
  }
  onMouseLeave() {
    clearInterval(this.setTimeEmitter)
    this.setState({ cursor: false })
  }
  onMouseMove(e) {
    // -> not ready -> ignore
    const { currentTime, duration, setProgressBarTime } = this.props
    if (!currentTime || !duration) {
      return
    }

    const progressBarTime = (duration / 100) * (e.nativeEvent.offsetX / e.target.offsetWidth * 100)
    clearInterval(this.setTimeEmitter)
    this.setTimeEmitter = setInterval(() => setProgressBarTime(progressBarTime), 4)
    this.setState({ cursor: true })
  }
  render() {
    const { currentTime, duration, focused, progressBarTime, buffered, onSeek } = this.props
    const isFocused = focused || this.state.cursor
    const progressTime = isFocused ? progressBarTime : currentTime
    const indicator = (isNumber(duration) && isNumber(currentTime)) ? (currentTime / duration) : 0
    const userIndicator = (isNumber(duration) && isNumber(progressBarTime)) ? (progressBarTime / duration) : 0
    const bufferedAmount = (isNumber(duration) && isNumber(buffered)) ? (buffered / duration) : 0

    return (
      <div className={style.ProgressBar}>
        <div className='barWrapper'>
          <div className='playTimeContainer'>
            <div className='playTimeDisplay'>{fancyTimeFormat(progressTime)}</div>
            <div className='playTimeDisplay'>{fancyTimeFormat(duration || null)}</div>
          </div>
          <div
            className='bar'
            onMouseMove={this.onMouseMove}
            onMouseLeave={this.onMouseLeave}
            onClick={onSeek}
          />
          <div className='buffered' style={{ width: `${(bufferedAmount || 0) * 100}%` }} />
          <div className='indicator' style={{ width: `${(indicator || 0) * 100}%` }} />
          { isFocused && <div className='user-indicator' style={{ width: `${(userIndicator || 0) * 100}%` }} /> }
        </div>
      </div>
    )
  }
}
