import { replace as replacer } from 'connected-react-router'
import uniq from 'lodash/uniq'
import ERRORS from '../../errors/messages'
import { addError } from '../../store/modules/modal'
import $badger from '../../lib/badger'

export const REPLACE = 'navigation/REPLACE'
export const BACK = 'navigation/BACK'
export const EXIT = 'navigation/EXIT'
export const PUSH = 'navigation/NAV_PUSH'
export const TOGGLE_SETTINGS = 'navigation/TOGGLE_SETTINGS'

export const toggleSettings = () => {
  return { type: TOGGLE_SETTINGS }
}

export const replace = path => {
  return dispatch => {
    dispatch({type: REPLACE, path: document.location.href.replace(document.location.origin, '')})
    dispatch(replacer(path))
  }
}

export const push = (route) => dispatch => {
  dispatch({type: PUSH, route})
  dispatch(replacer(route))
}

export const back = () => {
  const HOME = '/music/widescreen_catalog'
  const LINKING = '/linking'
  return (dispatch, getState) => {
    const stack = getState().nav.stack

    if(stack.length) {
      const historicPath = stack[stack.length - 1]
      dispatch({type: BACK})
      dispatch(replacer(historicPath))
      return false
    }

    if (document.location.href.includes(LINKING) || document.location.href.includes(HOME)) {
      exit()(dispatch);
      return false
    }

    return dispatch(replacer(HOME))
  }
}

export const replaceBack = () => {
  const HOME = '/music/widescreen_catalog'
  const LINKING = '/linking'
  return (dispatch, getState) => {
    const stack = getState().nav.stack

    if(stack.length) {
      const historicPath = stack[stack.length - 1]
      dispatch({type: BACK})
      window.location.replace(historicPath)
      return false
    }

    if (document.location.href.includes(LINKING) || document.location.href.includes(HOME)) {
      exit()(dispatch);
      return false
    }

    window.location.replace(HOME)
  }
}

export const exit = () => {
  return dispatch => {
    console.info("Dispatching Exit This Application")
    dispatch(addError(ERRORS.exit_this_application(() => {
      console.info("Calling Exit", $badger.shutdown)
      try {
        // lg
        window.webOS && window.webOS.platformBack()
        // samsung
        window.tizen && window.tizen.application.getCurrentApplication().exit()
      } catch (_) {}
      console.info("Calling Badger Shutdown ")
      try  {
        $badger.shutdown()
      } catch(_) {
        console.info("Error? "+ _.message)
      }
      // window.close()
      dispatch({ type: EXIT })
    })))
  }
}

const initialState = {
  stack: [],
  showSettings: false,
}

const pushAction = (state, route) => {
  if (route === undefined) return state
  const newish = Object.assign({}, state)
  newish.stack.push(route)
  return uniq(newish)
}

const addToStack = (state, path) => {
  const newish = Object.assign({}, state)
  newish.stack.push(path)
  return newish
}

const removeFromStack = (state) => {
  const newish = Object.assign({}, state)
  newish.stack.pop()
  return newish
}

export default function hisReducer (state = initialState, action) {
  switch (action.type) {
    case PUSH:
      return pushAction(state, action.route)
    case BACK:
      return removeFromStack(state)
    case REPLACE:
      return addToStack(state, action.path)
    case TOGGLE_SETTINGS:
      return Object.assign({}, state, { showSettings: !state.showSettings })
    default:
      return state
  }
}
