import { call, put, takeLatest } from 'redux-saga/effects'
import Cookie from 'js-cookie'
import gt from 'lodash/get'
import i18n from 'i18next'
import store from '..'
import {
  getCode,
  pollForCode,
  refreshToken,
  getAuthData,
  storeAuthData,
  deleteAuthData
} from '../../services/auth'
import {
  CLEAR_AUTH_DATA,
  REQUEST_ACCESS_TOKEN,
  SET_TEMPORARY_REFRESH_TOKEN,
  CLEAR_TEMPORARY_REFRESH_TOKEN,
  TRACK_ACCESS_TOKEN,
  requestAccessToken
} from '../modules/auth'
import {
  LINKING_FETCHING_CODE_SUCCESS,
  LINKING_FETCHING_CODE_FAILED,
  LINKING_FETCHING_CODE,
  LINKING_POLL_FOR_CODE,
  LINKING_CANCELED,
} from '../modules/linking'
import API from '../../services/service'
import config from '../../config'

function * getAuthDeviceCode (action) {
  try {
    const payload = yield call(getCode)
    yield put({type: LINKING_FETCHING_CODE_SUCCESS, payload})
    yield put({type: LINKING_POLL_FOR_CODE, payload})
  } catch (e) {
    console.warn(e)
    // TODO: This should restart the code process (get a new code)
    // on certain errors. See step 4 at
    // https://developer.amazon.com/docs/alexa-voice-service/code-based-linking-other-platforms.html
    switch (e.error) {
      case 'canceled':
        yield put({type: LINKING_CANCELED})
        break
      case 'invalid_code_pair':
        yield put({type: LINKING_FETCHING_CODE})
        break
      default:
        yield put({type: LINKING_FETCHING_CODE_FAILED, message: e.message})
    }
  }
}

function * getTokenFromPolling (action) {
  try {
    const payload = yield call(pollForCode, action.payload)
    yield put({type: TRACK_ACCESS_TOKEN, payload})
    yield call(storeAuthData, payload)
  } catch (e) {
    console.error('saga error with token from polling ', e)
    yield put({type: LINKING_FETCHING_CODE, message: e.error})
  }
}

function * refreshTokenGen () {
  console.log('requesting new token...')
  try {
    const { temp_refresh_token, refresh_token } = store.getState().auth;
    const curr_refresh_token = (!!temp_refresh_token) ? temp_refresh_token : refresh_token;
    if (temp_refresh_token) yield put({ type: CLEAR_TEMPORARY_REFRESH_TOKEN })
    const payload = yield call(refreshToken, curr_refresh_token)
    const data = gt(payload,'response.data', null)
    if (payload && data && data.error) throw data
    const expires = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    Cookie.set('amzn_music_auth', payload, { expires })
    yield put({type: TRACK_ACCESS_TOKEN, payload})
  } catch (e) {
    console.error('There is an error getting access_token using refresh_token; removing Cookie', e)
    Cookie.remove('amzn_music_auth')
    i18n.changeLanguage(config.default_language)
    window.location.href = '/'
  }
}

function * clearAuthData (action) {
  try {
    API.deleteToken()
    yield call(deleteAuthData)
  } catch (e) {
    console.error(e)
  }
}

function * registerPathChange (action) {
  try {
    const {pathname} = action.payload.location
    if (/^\/?linking(\/|$)/.test(pathname)) { // TODO: Need a mechanism for managing these
      const authData = yield call(getAuthData)
      if (authData && authData.access_token && authData.refresh_token) {
        const temp_refresh_token = authData.refresh_token;
        yield put({type: SET_TEMPORARY_REFRESH_TOKEN, temp_refresh_token })
        yield put(requestAccessToken())
      } else {
        yield put({type: LINKING_FETCHING_CODE})
        if (authData) API.deleteToken()
      }
    }
  } catch (e) {
    console.warn(`Error with path change for linking ${e.message}, trying to get linking code`, e)
    yield put({type: LINKING_FETCHING_CODE})
  }
}

function * authSaga () {
  yield takeLatest(LINKING_FETCHING_CODE, getAuthDeviceCode)
  yield takeLatest(LINKING_POLL_FOR_CODE, getTokenFromPolling)
  yield takeLatest(REQUEST_ACCESS_TOKEN, refreshTokenGen)
  yield takeLatest(CLEAR_AUTH_DATA, clearAuthData)
  yield takeLatest('@@router/LOCATION_CHANGE', registerPathChange)
}

export default authSaga
