import React from 'react'
import cx from 'classnames'
import css from './PlayerControlButton.scss'
import buttonComposer from '../../lib/reactv-navigation/components/Buttonizer/Buttonizer'

const PlayerControlButton = ({ focused, style, children, className, disabled, onClick, onMouseOver }) => (
  <div
    className={cx(css.PlayerControlButton, { focused, disabled }, className)}
    style={style}
    onClick={!disabled ? onClick : undefined}
    onMouseOver={onMouseOver}
  >
    {children}
  </div>
)

export default buttonComposer(PlayerControlButton)
