import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import KeyEvents from '../../lib/reactv-navigation/KeyEvents'
import Playback from './Playback'
import { sendPlaybackEvent } from '../../store/modules/tracks'
import { replace, back } from '../../store/modules/nav'
import { addError, openModal } from '../../store/modules/modal'
import { playerCurrentSrc, setCurrentTime, setPlayerState, setProgressBarTime } from '../../store/modules/player'
import { noha, normalizeErrorActions } from '../../lib/utils'
import gt from 'lodash/get'
import { getTrackInstance, getPlayableNode } from './selectors'
import PageLoading from '../../components/PageLoading'
import ERRORS from '../../errors/messages'
import createDebug from 'debug'

const debug = createDebug('app:PlaybackContainer')

const keys = new KeyEvents()

const mapStateToProps = (state) => ({
  stack: state.nav.stack,
  errorRef: state.modal.error,
  showModal: state.modal.showModal,
  buffered: state.player.buffered,
  progressBarTime: state.player.progressBarTime,
  thumbs: state.thumbs,
  duration: state.player.duration,
  playerState: state.player.playerState,
  currentTime: state.player.currentTime,
  currentUrl: state.player.currentUrl,
  trackInstance: getTrackInstance(state),
  enclosing: getPlayableNode(state)
})

const mapDispatchToProps = {
  sendPlaybackEvent,
  openModal,
  addError,
  setProgressBarTime,
  replace,
  back,
  playerCurrentSrc,
  setCurrentTime,
  setPlayerState
}

class PlaybackContainer extends Component {
  constructor(p) {
    super(p)
    this.showPlaybackError()
  }

  showPlaybackError (prevProps) {
    const { enclosing, addError } = this.props;
    if (enclosing && enclosing.generalErrorReports && (!prevProps || !prevProps.enclosing)) {
      const error = enclosing.result;
      if (enclosing.generalErrorReports[noha(error)]) {
        const generalErrorReport = Object.assign({}, enclosing.generalErrorReports[noha(error)]);
        addError({ code: 306, ref: noha(error), autoSkipOnError: false, report: normalizeErrorActions(generalErrorReport, [{ label: i18next.t('Go back'), resume: false, action: 'back' }]) })
      }
    }
  }

  monitorBuffer() {
    const heartbeat_frequency = 100;
    this.props.sendPlaybackEvent('stop', 'bufferUnderflow')
    this.bufferTime = 0;
    clearInterval(this._heartbeat)
    this._heartbeat = setInterval(() => {
      this.bufferTime += heartbeat_frequency;
    }, heartbeat_frequency)
  }

  stopMonitoringBuffer() {
    this.props.sendPlaybackEvent('start', 'bufferUnderflow', { playbackDelay: this.bufferTime })
    this.bufferTime = 0;
    clearInterval(this._heartbeat)
  }

  playIfReady(newTime) {
    const { buffered, setCurrentTime, setPlayerState, sendPlaybackEvent } = this.props;
    if (buffered > newTime) {
      if (this.bufferTime > 0) this.stopMonitoringBuffer()
      sendPlaybackEvent('start', 'userSeek')
      setCurrentTime(newTime)
      setPlayerState('playing') // PLAY SONG
      clearTimeout(this.resumeIn)
    } else {
      if (!this.bufferTime) this.monitorBuffer();
    }
  }

  seek(direction) {
    const { currentTime, playerState, setPlayerState, duration, progressBarTime, setProgressBarTime, sendPlaybackEvent } = this.props

    const SEEK_IN_SECONDS = 10
    const PLAYBACK_DELAY_IN_MILLISECONDS = 800

    let newTime = progressBarTime

    // -> to right, and selected time is smaller than current time -> normilize
    if (direction === +1 && newTime < currentTime) {
      newTime = currentTime
    }

    // -> to left, and selected time is grater than current time -> normilize
    if (direction === -1 && newTime > currentTime) {
      newTime = currentTime
    }

    // -> doing left/right -> add/minus X sec
    if (direction !== undefined) {
      newTime += SEEK_IN_SECONDS * direction
    }

    // -> new time grater than duration -> normalize
    if (newTime > duration) {
      newTime = duration
    }

    // -> new time smaller than 0 -> normalize
    if (newTime < 0) {
      newTime = 0.001
    }

    clearTimeout(this.resumeIn)

    if (playerState === 'playing') { // PAUSE SONG
      sendPlaybackEvent('stop', 'userSeek')
      setPlayerState('paused')
    }

    setProgressBarTime(newTime)
    // minimum delay = X milliseconds to avoid player.play() on first onLeft
    this.resumeIn = setInterval(() => { this.playIfReady(newTime) }, PLAYBACK_DELAY_IN_MILLISECONDS)
  }

  componentDidMount () {
    // Invert seek direction because it was reported to work backwards.
    // this._unsubRewind = keys.subscribeTo('Rewind', () => this.seek(-1))
    // this._unsubFastForward = keys.subscribeTo('FastForward', () => this.seek(+1))
    this._unsubRewind = keys.subscribeTo('Rewind', () => this.seek(+1))
    this._unsubFastForward = keys.subscribeTo('FastForward', () => this.seek(-1))
    debug('Mounted Playback Container')
    this.handleTrackPlayback()
  }

  componentDidUpdate (prevProps) {
    const { trackInstance, enclosing, errorRef, addError, playerState, openModal, showModal } = this.props;
    this.showPlaybackError(prevProps)
    if (trackInstance && trackInstance.trackDefinitionData) {
      // current track contains an error
      const { error, autoSkipOnError, audio } = trackInstance.trackDefinitionData;
      if (error) {
        if (playerState === 'playing') this.props.setPlayerState('paused')
        if (prevProps.trackInstance !== trackInstance || !errorRef) {
          // new track or error not present
          const generalErrorReport = Object.assign({}, enclosing.generalErrorReports[noha(error)])
          addError({ code: 306, ref: noha(error), autoSkipOnError, report: normalizeErrorActions(generalErrorReport, [{ label: i18next.t('Go back'), resume: false, action: 'back' }]) })
        } else if (!showModal && errorRef && playerState === 'playing') {
          // error present in current track and attempted to play
          openModal()
        }
      } else if (audio.expires < new Date().toISOString()) {
        addError(ERRORS.audio_expired, true)
      }
    }
  }

  componentWillUnmount () {
    debug('Unmounting Mounted Playback Container')
    clearTimeout(this.resumeIn)
    if (this._unsubRewind) this._unsubRewind.unsubscribe()
    if (this._unsubFastForward) this._unsubFastForward.unsubscribe()
    this._unsubRewind = null
    this._unsubFastForward = null
  }

  handleTrackPlayback () {
    const {trackInstance, playerCurrentSrc, currentUrl} = this.props
    if (trackInstance) {
      const src = gt(trackInstance, 'trackDefinitionData.audio.uri', null)
      if (src && currentUrl !== src) playerCurrentSrc(src)
    }
  }

  render () {
    const { trackInstance, showModal, enclosing } = this.props;
    if (trackInstance && trackInstance.trackDefinitionData) {
      const {
        audio,
        autoSkipOnError,
        duration,
        error,
        isExplicit,
        trackTag,
        ...rest
      } = trackInstance.trackDefinitionData
      return (<Playback {...rest}
        thumbs={this.props.thumbs}
        focused={!showModal}
        menuid={'playback-container'}
        onFocusItem='trackInfo'
        seek={this.seek.bind(this)}
        self={trackInstance.self}/>)
    } else if (enclosing && enclosing.result) {
      return null
    } else {
      return (<PageLoading />)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaybackContainer)
