import React, { Component } from 'react'
import MainMenu from './MainMenu'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import RowMenuComposer from '../../lib/reactv-navigation/components/RowMenu/RowMenuComposer'
import { updateMenu } from '../../lib/reactv-redux/MenusReducer'
import { clearMenu, updateMenuState } from '../../lib/reactv-redux/ReacTVReduxReducer'
import { replace, toggleSettings } from '../../store/modules/nav'
import { resetSearch } from '../../store/modules/search'

const ComposedMenu = RowMenuComposer(MainMenu)
const mapStateToProps = ({ router, topnav, menus }) => ({
  menus,
  topnav,
  pathname: router.location.pathname,
})
const mapDispatchToProps = {
  replace,
  toggleSettings,
  resetSearch,
  clearMenu,
  updateMenu,
  updateMenuState,
}

class MainMenuContainer extends Component {
  handleSelection(dest) {
    const { onEnter, replace, toggleSettings } = this.props
    if (dest.path === '/widescreen_search') {
      this.props.clearMenu('home:/widescreen_search')
      Object.keys(this.props.menus).forEach(menuid => {
        if (/search_summary$/.test(menuid)) this.props.clearMenu(menuid)
      })

      this.props.resetSearch()
      this.props.updateMenu('search:atoz', { index: 0 })
      this.props.updateMenuState('searchspace', 'search:atoz')
    }

    if (dest.path === '/settings') {
      toggleSettings()
    } else {
      replace(dest.path)
    }

    if (onEnter && typeof onEnter === 'function') {
      onEnter(dest)
    }
  }
  render() {
    const { onEnter, updateMenu, topnav, menu, t, ...rest } = this.props
    const menuItems = topnav.map(menu => ({ ...menu, name: t(menu.name) }))
    return (
      <ComposedMenu
        {...rest}
        menuItems={menuItems}
        onEnter={this.handleSelection.bind(this)}
        onLeft={() =>
          this.props.updateMenu('topnav', {
            index: topnav.length - 1
          })}
      />)
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MainMenuContainer))
