import React from 'react'
import TrackInfo from '../../components/TrackInfo'
import './Playback.scss'
import Space from '../../lib/reactv-redux/SpaceRedux'
import ProgressBar from '../../components/ProgressBar'
import Buttonizer from '../../lib/reactv-navigation/components/Buttonizer'
import { transcodeImg } from '../../lib/utils'
import ReactModal from 'react-modal'

const ScrubbableProgressBar = Buttonizer(({focused, onSeek}) => (
  <ProgressBar focused={focused} onSeek={onSeek} />
))

const Playback = ({isFocused, title, artist, album, image, changeFocus, seek, trackRating, focused, thumbs, self }) => (
  <div className='Playback Page'>
    <div className='Playback-background' style={image ? {backgroundImage: `url(${transcodeImg(image.uri)})` } : { backgroundSize: 'cover' }} />
    <ScrubbableProgressBar
      onLeft={() => { seek(-1) }}
      onRight={() => { seek(+1) }}
      onUp={changeFocus('trackInfo')}
      onSeek={() => { seek() }}
      focused={focused && isFocused('progressbar')}
      mid='progressbar'/>
    <TrackInfo
      title={title}
      artist={artist}
      album={album}
      image={image}
      self={self}
      focused={focused && isFocused('trackInfo')}
      menuid='track-info-button'
      onFocusItem={'playback:playercontrols'}
      onDown={changeFocus('progressbar')}
      trackRating={trackRating}/>
    <ReactModal className='PlaybackResponse' portalClassName='PlaybackResponsePortal' isOpen={thumbs.showMessage}>
      <label>{thumbs.message}</label>
    </ReactModal>
  </div>
)

export default Space(Playback)
