import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import cx from 'classnames'
import i18n from 'i18next'
import { history } from '../../store'
import { Buttonizer } from '../../lib/reactv-navigation'
import Space from '../../lib/reactv-redux/SpaceRedux'

const mapStateToProps = () => ({
  history
})

const mapDispatchToProps = {}

let ActionModalButton = Buttonizer(
  ({focused, label, onEnter}) => (
    <div className={cx('ActionModalButton', { focused })} onClick={onEnter}><label>{label}</label></div>
  )
)

class Modal extends Component {
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.props.discardModal();
    });
  }

  componentWillUnmount() {
    this.unlisten && this.unlisten();
  }

  render() {
    const { showModal, generalErrorReports: error, isFocused, changeFocus, handleModalClick } = this.props;
    if (showModal) {
      return (
        <ReactModal
          className='UnlimitedModal'
          isOpen={showModal}
          contentLabel="Minimal Modal Example">
            <label className='Title'>{i18n.t(error.brief)}</label>
            <label className='Subtitle' dangerouslySetInnerHTML={{__html: error.explanation && i18n.t(error.explanation.replace(/(?:\r\n|\r|\n)/g, '<br />'))}} />
            <div className='ErrorModalActions'>
            {error.options && error.options.length > 0 &&
              <ActionModalButton
                mid='option#1'
                label={i18n.t(error.options[0].label)}
                focused={isFocused("option#1")}
                onEnter={() => handleModalClick(error.options[0])}
                onRight={(error.options.length > 1) ? changeFocus("option#2") : null}
              />}
            {error.options && error.options.length > 1 &&
              <ActionModalButton
                mid="option#2"
                label={i18n.t(error.options[1].label)}
                focused={isFocused("option#2")}
                onEnter={() => handleModalClick(error.options[1])}
                onLeft={changeFocus("option#1")}
              />}
            {(!error.options || !error.options.length) &&
              <ActionModalButton
                mid="option#1"
                label={i18n.t('OK')}
                focused={true}
                onEnter={() => handleModalClick()}
              />}
            </div>
        </ReactModal>
      );
    }
    return null
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Space(Modal))
