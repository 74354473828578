import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import gt from 'lodash/get'
import {
  getChildItemDescriptionsSelector,
  getChildNavigationNodeSummariesSelector,
  getChildItemPlayablesSelector,
  makeNavigationDescriptionSelector,
  getMenuIDsSelector,
  getChildItemPathname
} from '../../lib/selectors/node_selectors'
import { handleItemSelection, mergePath, noha, isEquivalent } from '../../lib/utils'
import HomeMenuHorizontalLoadingMenu from './HomeMenuHorizontalLoadingMenu'
import { deleteChildNode } from '../../store/modules/music'
import { replace } from '../../store/modules/nav'
import { addError } from '../../store/modules/modal'
import ERRORS from '../../errors/messages'
import './HomeMenuHorizontalLoadingMenu.scss'

const makeMapStateToProps = () => {
  const getNavigationDescriptionFromSummarySelector = makeNavigationDescriptionSelector()
  const mapStateToProps = (state, props) => {
    return {
      allMenuIDs: getMenuIDsSelector(state),
      menuState: state.menus[props.menuid],
      allowExplicit: state.playable.allowExplicit,
      itemDescriptions: getChildItemDescriptionsSelector(state, props), // needed for handleItemSelection
      navigationNodeSummaries: getChildNavigationNodeSummariesSelector(state, props), // needed for handleItemSelection
      childPath: getChildItemPathname(state, props), // needed for handleSelection
      pathname: state.router.location.pathname,
      playables: getChildItemPlayablesSelector(state, props), // needed for handleItemSelection
      summary: getNavigationDescriptionFromSummarySelector(state, props),
      upsale: state.upsale,
    }
  }
  return mapStateToProps
}

const mapDispatchToProps = { replace, addError, deleteChildNode }

class HomeMenuHorizontalLoadingMenuContainer extends Component {
  constructor (p) {
    super(p)
    const { summary, itemDescriptions, childPath, itemDescription: { navigationNodeSummary }, pathname, deleteChildNode } = p
    if (childPath && childPath.includes('upsell-banner' === false) && (!itemDescriptions || !summary.items.length)) {
      deleteChildNode(noha(navigationNodeSummary), childPath, pathname)
    }
    this.handleSelection = dest => {
      const { childPath, replace } = this.props
      if (dest.type === 'SEE_MORE') replace(`/list${childPath}`)
      else if (dest.itemLabel ===  i18next.t('See more') && childPath === '/widescreen_search') {
        const { description } = this.props.navigationNodeSummaries[noha(dest.navigationNodeSummary)]
        const endpoint = (description) ? `/${description}` : ''
        const listDest = mergePath('/list', childPath, endpoint)
        this.props.replace(listDest)
      } else handleItemSelection.call(this, dest, childPath)
    }
    this.handleOpenUpsaleModal = this.handleOpenUpsaleModal.bind(this)
  }

  shouldComponentUpdate (nextProps) {
    const shouldUpdate = (
      nextProps.focused !== this.props.focused
      || gt(nextProps, 'menuState.index') !== gt(this.props, 'menuState.index')
      || !isEquivalent(gt(nextProps, 'summary.itemsData'), gt(this.props, 'summary.itemsData'))
    )

    return shouldUpdate
  }

  static propTypes = {
    itemDescription: PropTypes.object.isRequired
  }

  handleOpenUpsaleModal () {
    this.props.addError(ERRORS.upsell_banner(this.props.upsale), false, '/widescreen_catalog')
  }

  render () {
    if (this.props.itemDescription) {
      const { childPath } = this.props
      if (childPath && childPath.includes('upsell-banner')) {
        // If the data is for 'Try Amazon Unlimited Music', manipulate API response to populate HomeMenuCard
        let summary = {
          image: ['#_obj0'],
          itemsData: [this.props.itemDescription],
          summary: '/upsell-banner/'
        }
        return (
          <HomeMenuHorizontalLoadingMenu
            menuid={this.props.menuid}
            {...summary}
            onClick={this.handleOpenUpsaleModal}
            focused={this.props.focused}
            name={this.props.itemDescription.navigationNodeSummary}
            allMenuIDs={this.props.allMenuIDs} />)
      }
    }
    if (this.props.summary && typeof(this.props.summary) === 'object') {
      return (
        <HomeMenuHorizontalLoadingMenu
          {...this.props.summary}
          menuid={this.props.menuid}
          menuState={this.props.menuState}
          slots={(/^\/widescreen_search/.test(this.props.pathname)) ? 2 : 3}
          onClick={this.handleSelection.bind(this)}
          focused={this.props.focused}
          name={this.props.itemDescription.navigationNodeSummary}
          allMenuIDs={this.props.allMenuIDs}
          allowExplicit={this.props.allowExplicit}
          onFarLeft={this.props.onFarLeft} />)
    } else {
      return null
    }
  }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(HomeMenuHorizontalLoadingMenuContainer)
