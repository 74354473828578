import React from 'react';
import Loader from 'react-loaders';
import './PageLoading.scss';
import cx from 'classnames';

class PageLoading extends React.Component {
  render() {
    const { className } = this.props;
    return (
      <div className={cx("page-loading",className)}>
        <Loader type="line-scale-pulse-out-rapid" active />
      </div>
    );
  }
}

export default PageLoading
