import { connect } from 'react-redux'
import {
  playerCurrentSrc,
  setBufferedTime,
  setPlayerControlsState,
  onCanPlay,
  setCurrentTime,
  setPlayerState,
  playerGotDuration,
  onReadyStateChange,
  onStarted,
  onEnded,
  onLoadEnd,
  onLoadStart,
} from '../../store/modules/player'
import { setPlaybackEvent, sendPlaybackEvent, clearPlaybackEvent } from '../../store/modules/tracks'
import gt from 'lodash/get'
import Player from './Player'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toggleSkipOnError, removeError } from '../../store/modules/modal'
import { getTrackInstance } from '../../pages/Playback/selectors'
import { handleTrackPlayback } from '../../lib/utils'
import $badger from '../../lib/badger';

const mapDispatchToProps = {
  removeError,
  playerCurrentSrc,
  toggleSkipOnError,
  clearPlaybackEvent,
  setPlaybackEvent,
  sendPlaybackEvent,
  setBufferedTime,
  setPlayerControlsState,
  onCanPlay,
  setCurrentTime,
  setPlayerState,
  onReadyStateChange,
  onStarted,
  onEnded,
  onLoadStart,
  onLoadEnd,
  gotDuration: playerGotDuration
}

const mapStateToProps = (state) => ({
  allowExplicit: state.playable.allowExplicit,
  tracks: state.tracks,
  shouldSkip: state.thumbs.shouldSkip,
  showModal: state.modal.showModal,
  autoSkipOnError: state.modal.autoSkipOnError,
  duration: state.player.duration,
  progressBarTime: state.player.progressBarTime,
  currentTime: state.player.currentTime,
  currentUrl: state.player.currentUrl,
  playerState: state.player.playerState,
  playerControlsState: state.player.playerControlsState,
  playbackEnded: state.player.playbackEnded,
  trackInstance: getTrackInstance(state)
})

class PlayerWrapper extends Component {
  constructor(p) {
    super(p);
    this.disableInitOnUpdate = true;
  }

  shouldComponentUpdate (nextProps) {
    const newUrl = (nextProps.currentUrl !== this.props.currentUrl);
    this.disableInitOnUpdate = (!newUrl)
    return true
  }

  componentDidUpdate (prevProps) {
    const { shouldSkip, autoSkipOnError, toggleSkipOnError, onStarted, trackInstance, playerCurrentSrc, removeError, allowExplicit, playbackEnded, currentUrl } = this.props;
    const trackDefinitionData = gt(trackInstance, 'trackDefinitionData', null)
    const src = gt(prevProps.trackInstance, 'trackDefinitionData.audio.uri', null)
    const newSrc = gt(trackDefinitionData, 'audio.uri', null)
    const isExplicit = gt(trackDefinitionData, 'isExplicit', null)

    if (this.props.playerControlsState !== prevProps.playerControlsState) {
      const trackTag =  gt(trackDefinitionData, 'trackTag', null);
      const title =  gt(trackDefinitionData, 'title', null);
      if (this.props.playerControlsState === 'playing')
        $badger.appActionMetricsHandler('CONTENT_STATE_CHANGE', 'STARTED', trackTag, title)
      else
        $badger.appActionMetricsHandler('CONTENT_STATE_CHANGE', 'STOPPED', trackTag, title)
    }

    if (newSrc && src !== newSrc) {
      removeError()
      if (currentUrl !== newSrc) playerCurrentSrc(newSrc)
    }
    const skipExplicit = (`${isExplicit}` === 'true' && !allowExplicit) ? true : false;
    const autoSkip = (!prevProps.autoSkipOnError && autoSkipOnError);
    if (autoSkip) toggleSkipOnError() // skipping song, toggle autoSkipOnError off
    if ((!prevProps.shouldSkip && shouldSkip) || playbackEnded || autoSkip || skipExplicit) handleTrackPlayback(1)
    if (!prevProps.playbackEnded && playbackEnded) onStarted()
  }

  render () {
    if (!this.props.currentUrl) {
      return null
    } else {
      const {
        allowExplicit,
        autoSkipOnError,
        playerCurrentSrc,
        removeError,
        shouldSkip,
        toggleSkipOnError,
        duration,
        progressBarTime,
        currentTime,
        currentUrl,
        playerState,
        playerControlsState,
        ...rest
      } = this.props;
      return <Player
        disableTimeUpdates={false}
        disableInitOnUpdate={this.disableInitOnUpdate}
        duration={duration}
        progressBarTime={progressBarTime}
        currentTime={currentTime}
        currentUrl={currentUrl}
        playerState={playerState}
        playerControlsState={playerControlsState}
        {...rest}
        />
    }
  }
}

PlayerWrapper.propTypes = {
  recommendation: PropTypes.object,
  playerControlsState: PropTypes.string,
  playerState: PropTypes.string,
  getNextRecommendation: PropTypes.func,
  recommendationError: PropTypes.func,
  currentTime: PropTypes.number
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerWrapper)
