export const SKIP_TRACK_DIRECTION = 'track/SKIP_TRACK_DIRECTION'
export const ADD_TRACK_TO_QUEUE = 'track/ADD_TRACK_TO_QUEUE'
export const CHANGE_QUEUE_IDX = 'track/CHANGE_QUEUE_IDX'
export const TOGGLE_SHUFFLE_STATUS = 'track/TOGGLE_SHUFFLE_STATUS'
export const CLEAR_PLAYBACK_EVENT = 'track/CLEAR_PLAYBACK_EVENT'
export const SEND_PLAYBACK_EVENT = 'track/SEND_PLAYBACK_EVENT'
export const SET_PLAYBACK_EVENT = 'track/SET_PLAYBACK_EVENT'
export const LOAD_TRACK_DEFINITION = 'track/LOAD_TRACK_DEFINITION'

export const reasonCodes = {
  start: ['userSelectTrack', 'userSelectPlayable', 'autoAdvance', 'userAdvance', 'userAdvanceBackward', 'userSeek', 'resume', 'bufferUnderflow'],
  stop: ['finish', 'userAdvance', 'userAdvanceBackward', 'userPause', 'userStop', 'userSeek', 'userInactive', 'systemPause', 'systemStop', 'error', 'bufferUnderflow']
}

export const isDirectedPlay = {
  userSelectTrack: true,
  userSelectPlayable: true,
  userAdvance: true,
  userAdvanceBackward: true,
  userSeek: true,
  resume: true,
  userPause: true,
  userStop: true
}

export function skipTrack(direction) {
  return {
    type: SKIP_TRACK_DIRECTION,
    direction
  }
}

export function addTrackToQ(track) {
  return {
    type: ADD_TRACK_TO_QUEUE,
    track
  }
}

export function deltaQIdx(delta) {
  return {
    type: CHANGE_QUEUE_IDX,
    delta
  }
}

export function toggleShuffleStatus(shuffle) {
  return {
    type: TOGGLE_SHUFFLE_STATUS,
    shuffle
  }
}

export function clearPlaybackEvent() {
  return { type: CLEAR_PLAYBACK_EVENT }
}

export function setPlaybackEvent(event,reason) {
  return {
    type: SET_PLAYBACK_EVENT,
    event, reason
  }
}

export function sendPlaybackEvent(event,reason,values) {
  return {
    type: SEND_PLAYBACK_EVENT,
    event, reason, values
  }
}

export function loadTrackDefinition(payload) {
  return {
    type: LOAD_TRACK_DEFINITION,
    ...payload
  }
}

const initialState = { event: '', reason: '', shuffle: false, queue: [], played: {}, qIdx: 0, direction: null }

export default function trackReducer(state = initialState, action) {
  switch(action.type) {
    case SKIP_TRACK_DIRECTION:
      const { direction } = action;
      return Object.assign({}, state, { direction })
    case ADD_TRACK_TO_QUEUE:
      const { track } = action;
      const queue = state.queue.slice(0)
      const played = Object.assign({}, state.played)
      queue.push(track);
      played[track] = true;
      return Object.assign({}, state, { queue, played })
    case CHANGE_QUEUE_IDX:
      const qIdx = state.qIdx + action.delta;
      return Object.assign({}, state, { qIdx })
    case TOGGLE_SHUFFLE_STATUS:
      return Object.assign({}, state, { queue: [], played: {}, shuffle: action.shuffle, qIdx: 0 })
    case SET_PLAYBACK_EVENT:
      const { event, reason } = action;
      return Object.assign({}, state, { event, reason })
    case CLEAR_PLAYBACK_EVENT:
      return Object.assign({}, state, { event: '', reason: '' })
    default:
      return state
  }
}
