import styled from 'styled-components'

export default styled.button`
  min-height: 3.125rem;
  min-width: 6.875rem;
  margin: 0 0.5rem;
  background: transparent;
  font-size: 1em;
  outline: 0 !important;
  border: none;
  box-sizing: border-box;
`