import React from 'react'
import {connect} from 'react-redux'
import {HomeGridContext} from '../../pages/Home/Home'
import HomeHorizontalLoadingMenu from '../HomeMenuHorizontalLoadingMenu'
import './HomeMenu.scss'

export const HomeMenuContext = React.createContext({
  onFocus: () => console.info('got default context for home menu'),
  mouseActive: false,
})

const mapStateToProps = ({mouse: {mouseActive}}) => ({mouseActive})

const HomeMenu = ({ focused, itemDescription, onFarLeft, idx, updateIndex, mouseActive}) => {
  return (
    <HomeGridContext.Consumer>
      {({onFocus}) => (
        <HomeMenuContext.Provider value={{
          onFocus: () => {
            console.info(`got focus on menu homemenu:${window && window.location && window.location.pathname}:${itemDescription.navigationNodeSummary}`)
            updateIndex(idx)
            onFocus()
          },
          mouseActive
        }}>
          <div className='HomeMenu'>
            <h1>{itemDescription.itemLabel}</h1>
            <HomeHorizontalLoadingMenu
              menuid={`homemenu:${window && window.location && window.location.pathname}:${itemDescription.navigationNodeSummary}`}
              focused={focused}
              itemDescription={itemDescription}
              onFarLeft={onFarLeft} />
          </div>
        </HomeMenuContext.Provider>
      )}
    </HomeGridContext.Consumer>
  )
}

export default connect(mapStateToProps)(HomeMenu)
