import React, { Component } from 'react'
import ReactModal from 'react-modal'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'
import Space from '../../lib/reactv-redux/SpaceRedux'
import { Buttonizer } from '../../lib/reactv-navigation'
import KeyEvents from '../../lib/reactv-navigation/KeyEvents'

const Keys = new KeyEvents()

let ActionModalButton = Buttonizer(
  ({focused, label, onEnter, onMouseOver}) => (
    <div className={cx('SettingsItem', { focused })} onClick={onEnter} onMouseOver={onMouseOver}><label>{label}</label></div>
  )
)

class Settings extends Component {
  componentDidMount() {
    this.backEvent = Keys.subscribeTo('Back', () => {
      this.emit = setTimeout(() => {
        try {
          this.props.handleExitButton()
        } catch (_) {}
      }, 100)
    })
  }

  componentWillUnmount() {
    clearTimeout(this.emit)
    this.backEvent && this.backEvent.unsubscribe()
  }

  render() {
    const { focused, isFocused, changeFocus, allowExplicit, handleUnlinkButton, handleExplicitButton, handleExitButton, t } = this.props;
      return (
        <ReactModal
          className='SettingsWindow'
          isOpen={focused}
          contentLabel="Minimal Modal Example">
          <div className='version'><label>{process.env.REACT_APP_AMAZON_MUSIC_VERSION}</label></div>
          <div className='SettingsList'>
              <ActionModalButton
                mid='explicit'
                label={t(`Explicit Songs: ${`${allowExplicit}` === 'true' ? 'Unblock' : 'Blocked'}`)}
                focused={isFocused("explicit")}
                onEnter={handleExplicitButton}
                onMouseOver={changeFocus('explicit')}
                onDown={changeFocus("exit")}
              />
              <ActionModalButton
                mid="exit"
                label={t('Close')}
                focused={isFocused("exit")}
                onEnter={handleExitButton}
                onMouseOver={changeFocus('exit')}
                onUp={changeFocus("explicit")}
                onDown={changeFocus("unlink")}
              />
              <ActionModalButton
                mid='unlink'
                label={t('Sign Out')}
                focused={isFocused("unlink")}
                onUp={changeFocus("exit")}
                onMouseOver={changeFocus('unlink')}
                onEnter={handleUnlinkButton}
              />
              <div className='termsOfUse'>{process.env.REACT_APP_AMAZON_TERMS}</div>
          </div>
        </ReactModal>
      )
  }
}

export default withTranslation()(Space(Settings))
