import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import API from '../../services/service'
import './Modal.scss'
import { closeModal, handleError } from '../../store/modules/modal'
import { back, replaceBack } from '../../store/modules/nav'
import { loadChildNode } from '../../store/modules/music'
import Modal from './Modal'
import { clearMenu } from '../../lib/reactv-redux/ReacTVReduxReducer';
const mapStateToProps = (state) => ({
  playable: state.playable,
  modal: state.modal
})

const mapDispatchToProps = { clearMenu, closeModal, handleError, loadChildNode, back, replaceBack }

class ModalContainer extends Component {
  constructor() {
    super();
    this.handleModalClick = this.handleModalClick.bind(this);
    this.discardModal = this.discardModal.bind(this);
  }

  handleModalClick (option) {
    const { handleError, modal, playable, loadChildNode } = this.props;

    this.discardModal();

    if (typeof option !== 'object' || option === null) return false

    switch (option.action) {
      case 'reload':
        loadChildNode(playable.node)
        break;
      case 'www':
      case 'refresh':
        window.location.reload()
        break;
      case 'back':
        this.props.back()
        break;
      case 'replaceBack':
        this.props.replaceBack()
        break;
      case 'logout':
        API.deleteToken()
        break;
      default:
        try {
          option.action.call(this)
        } catch (_) {}
        break;
    }

    if (option.resume) handleError({ option, source: modal.source })
  }

  discardModal() {
    const { clearMenu, closeModal } = this.props;

    clearMenu('modal');
    closeModal();
  }

  componentWillMount() {
    ReactModal.setAppElement('body');
  }

  render () {
    const { modal: { showModal, generalErrorReports } } = this.props;
    if (showModal) {
      return (
          <Modal
          focused={true}
          menuid={'modal'}
          onFocusItem='option#1'
          handleModalClick={this.handleModalClick}
          discardModal={this.discardModal}
          showModal={showModal}
          generalErrorReports={generalErrorReports}
          />
      )
    }
    return null
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
