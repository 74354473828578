import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cx from 'classnames'
import css from './Catalog.scss'
import PlaylistMenu from '../../components/PlaylistMenu'
import Button from '../../components/PlayerControlButton'
import { transcodeImg } from '../../lib/utils'
import CloseIcon from '../../assets/images/icon/closeButton'
import { back } from '../../store/modules/nav'

const mapDispatchToProps = (dispatch) => {
  const creators = bindActionCreators({
    back
  }, dispatch)
  return creators
}

const Catalog = ({kid, onSelect, thumbnail, itemsData, title, passRef, showModal, allowExplicit, back}) => (
  <div className={cx(css.Catalog, 'Page')}>
    <div className={'info'}>
      <div className="infoHeader">
        <div className="infoBackButton">
          <Button
            disabled={false}
            onClick={back}
            className={cx('closeButton')}
          >
            <CloseIcon />
          </Button>
        </div>
        {title && <label className='title'>{title}</label>}
      </div>
      {thumbnail && (<img src={transcodeImg(thumbnail.uri).replace(/SX[0-9]+_SY[0-9]+_\.png$/, 'SX1000_SY1000_.png')} alt={title || ''} className='image' />)}
    </div>
    <PlaylistMenu
      menuid={`catalogmenu:${kid}`}
      data={itemsData}
      focused={!showModal}
      onClick={onSelect}
      passRef={passRef}
      slots={6}
      allowExplicit={allowExplicit} />
  </div>
)

export default connect(null, mapDispatchToProps)(Catalog)
