export const LOAD_CURRENT_NODE = 'MUSIC/LOAD_CURRENT_NODE'
export const PUSH_CURRENT_NAVIGATION_NODE = 'MUSIC/PUSH_CURRENT_NAVIGATION_NODE'
export const LOAD_CHILD_NODE = 'MUSIC/LOAD_CHILD_NODE'
export const SET_CHILD_NODE = 'MUSIC/SET_CHILD_NODE'
export const ADD_CHILD_NODE = 'MUSIC/ADD_CHILD_NODE'
export const DELETE_CHILD_NODE = 'MUSIC/DELETE_CHILD_NODE'
export const CLEAR_ALL_NODES = 'MUSIC/CLEAR_ALL_NODES'
export const SET_TRACKRATING = 'MUSIC/SET_TRACKRATING'
export const UPDATE_NODE_TRACK = 'MUSIC/UPDATE_NODE_TRACK'

export function clearNodes() {
  return {
    type: CLEAR_ALL_NODES
  }
}
export function setChildNode(payload) {
  return {
    type: SET_CHILD_NODE,
    payload,
  }
}
export function loadChildNode(path, options) {
  return {
    type: LOAD_CHILD_NODE,
    path,
    retry: options ? options.retry : null
  }
}
export function addChildNode(node, path, resolvePath) {
  return {
    type: ADD_CHILD_NODE,
    node, path: decodeURIComponent(path), resolvePath
  }
}
export function deleteChildNode(navNodeSum, path, pathname) {
  return {
    type: DELETE_CHILD_NODE,
    navNodeSum, pathname, path: decodeURIComponent(path)
  }
}
export function updateNodeTrack(payload) {
  return {
    type: UPDATE_NODE_TRACK,
    payload
  }
}

const initialState = {
  currentParent: null,
  nodes: {},
  pathResolvers: {},
  errorMsg: {}
}

const addNode = (state, {node, path, resolvePath, payload}) => {
  const key = path || node.result
  let newNodes = Object.assign({}, state.nodes)
  newNodes[key] = node
  const newState = Object.assign({}, state, { nodes: newNodes })
  newState.pathResolvers[path] = resolvePath
  if (node.generalErrorReports) newState.errorMsg = { generalErrorReports: node.generalErrorReports }
  return newState
}

const deleteNode = (state, {navNodeSum, path, pathname}) => {
  let newNodes = Object.assign({}, state.nodes)
  delete newNodes[path]
  const parent = pathname.replace(/^\/*music\/*/,'/');
  delete newNodes[parent].navigationNodeSummaries[navNodeSum]
  const newState = Object.assign({}, state, { nodes: newNodes })
  delete newState.pathResolvers[path]
  return newState
}

const updateTrackDefinition = (state, { node, track_def, trackDefinition }) => {
  const newTrackDefs = Object.assign({}, state.nodes[node].trackDefinitions, { [track_def]: trackDefinition });
  const newState = Object.assign({}, state)
  newState.nodes[node].trackDefinitions = newTrackDefs;
  return newState
}

const setTrackRating = (state, { data, payload }) => {

  let newNodes = JSON.parse(JSON.stringify(state.nodes))
  const newState = Object.assign({}, state, { nodes: newNodes })
  const track_def = 'track_def_' + data.indexWithinChunk
  newState.nodes[data.node].trackDefinitions[track_def].trackRating.thumbRating = payload
  return newState
}

export default function musicReducer(state = initialState, action) {
  let newState;
  switch(action.type) {
    case UPDATE_NODE_TRACK:
      return updateTrackDefinition(state, action.payload)
    case PUSH_CURRENT_NAVIGATION_NODE:
      newState = addNode(state, action.payload)
      return Object.assign({}, newState, { currentParent: action.payload.result })
    case ADD_CHILD_NODE:
      return addNode(state, action)
    case DELETE_CHILD_NODE:
      return deleteNode(state, action)
    case SET_TRACKRATING:
      return setTrackRating(state, action)
    case CLEAR_ALL_NODES:
      return initialState
    default:
      return state
  }
}
