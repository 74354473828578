import React from 'react'
import Space from '../../lib/reactv-redux/SpaceRedux'
import MainMenu from '../../components/MainMenu'
import Home from './Home'

const HomeComponent = Space((p) => (
  <>
    <MainMenu
      menuid='topnav'
      mid='topnav'
      focused={p.isFocused('topnav')}
      onDown={(p.entryFocus) ? p.changeFocus(p.entryFocus) : null}
      onRight={() => p.updateMenu('topnav', { index: 0 })}
      updateMenu={p.updateMenu}
      onMouseOver={p.changeFocus('topnav')}
    />
    <Home {...p} onFocus={() => {
      console.info('got focus on home grid')
      p.updateMenu(p.menuid)
    }} />
  </>
))

export default HomeComponent
