import { takeLatest, put } from 'redux-saga/effects'
import { mergeChunkWithPathAndQuery } from '../../lib/utils'
import { updateTopnav } from '../modules/topnav'
import { SET_CURRENT_PLAYABLE } from '../modules/playable'

function * setTopnav (action) {
  const { node, chunk, indexWithinChunk } = action;
  const path = mergeChunkWithPathAndQuery(['/playback', node], chunk, { indexWithinChunk })
  yield put(updateTopnav(path))
}

function * topnavSaga () {
  yield takeLatest(SET_CURRENT_PLAYABLE, setTopnav)
}

export default topnavSaga
