export const UPDATE_ALL_NODES = 'CACHE/UPDATE_ALL_NODES'

export function updateAllNodes(payload) {
  return {
    type: UPDATE_ALL_NODES,
    payload
  }
}

const initialState = {}

export default function cacheReducer(state = initialState, action) {
  let newState;
  switch(action.type) {
    case UPDATE_ALL_NODES:
      newState = Object.assign({}, state)
      const { menuid, prevNode, currentNode, nextNode } = action.payload;
      newState[menuid] = { prevNode, currentNode, nextNode }
      return newState
    default:
      return state
  }
}
