import React from 'react'

const SearchButton = ({fill}) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
    <defs>
      <path id="m7q3a" d="M22.707,21.293 L17.025,15.611 C18.258,14.071 19,12.122 19,10 C19,5.038 14.963,1 10,1 C5.038,1 1,5.038 1,10 C1,14.963 5.038,19 10,19 C12.122,19 14.071,18.258 15.611,17.025 L21.293,22.707 C21.488,22.902 21.744,23 22,23 C22.256,23 22.512,22.902 22.707,22.707 C23.098,22.316 23.098,21.684 22.707,21.293 Z M10,17 C6.14,17 3,13.859 3,10 C3,6.14 6.14,3 10,3 C13.859,3 17,6.14 17,10 C17,13.859 13.859,17 10,17 Z"/>
    </defs>
    <g><g opacity="1">
      <use fill={fill ? fill : "#fff"} xlinkHref="#m7q3a"/>
    </g></g>
  </svg>
)

export default SearchButton
