import { combineReducers } from 'redux'
import linking from './linking'
import mouse from './mouse';
import auth from './auth'
import music from './music'
import tracks from './tracks'
import search from './search'
import pages from './pages'
import playable from './playable'
import player from './player'
import topnav from './topnav'
import {default as nav} from './nav'
import { ReactTVReduxReducer, MenusRedux } from '../../lib/reactv-redux'
import modal from './modal'
import cache from './cache'
import thumbs from './thumbs'
import stations from './stations'
import lng from './lng'
import upsale from './upsale'

export default combineReducers({
  cache,
  thumbs,
  linking,
  auth,
  mouse,
  topnav,
  music,
  menus: ReactTVReduxReducer,
  tracks,
  nav,
  player,
  playable,
  search,
  pages,
  navigation: MenusRedux,
  stations,
  modal,
  lng,
  upsale,
})
