import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setProgressBarTime } from '../../store/modules/player'
import ProgressBar from './ProgressBar'

const mapStateToProps = (state, ownProps) => ({
  buffered: state.player.buffered,
  progressBarTime: state.player.progressBarTime,
  currentTime: state.player.currentTime,
  duration: state.player.duration,
  currentUrl: state.player.currentUrl
})

const mapDispatchToProps = {
  setProgressBarTime,
}

class ProgressBarContainer extends Component {
  render () {
    return <ProgressBar {...this.props} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBarContainer)
