export const UPDATE_MENU_STATE = 'reactv-redux/UPDATE_MENU_STATE'
export const BATCH_UPDATE_MENU_STATE = 'reactv-redux/BATCH_UPDATE_MENU_STATE'
export const CLEAR_MENU_STATE = 'reactv-redux/CLEAR_MENU_STATE'

export function updateMenuState (menuid, state) {
  return {
    type: UPDATE_MENU_STATE, menuid, state
  }
}

export function batchUpdateMenuState (menus) {
  return {
    type: BATCH_UPDATE_MENU_STATE,
    menus
  }
}

export function clearMenu (menuid) {
  return {
    type: CLEAR_MENU_STATE, menuid
  }
}

function cookBatch (menus, state) {
  let newState = Object.assign({}, state);
  Object.keys(menus).map((key, index) => {
    newState[key].index = menus[key].index
    newState[key].slotIndex = menus[key].slotIndex
    return null
  })
  return newState
}

const initialState = {}

export default function reactvReduxReducer (state = initialState, action) {
  switch (action.type) {
    case UPDATE_MENU_STATE:
      let current = state[action.menuid], updated
      if (typeof current === 'object') updated = Object.assign({}, current, action.state)
      else updated = action.state
      return Object.assign({}, state, {[action.menuid]: updated})
    case BATCH_UPDATE_MENU_STATE:
      return cookBatch(action.menus, state)
    case CLEAR_MENU_STATE:
      const newstate = Object.assign({}, state)
      delete newstate[action.menuid]
      return newstate
    default:
      return state

  }
}
