import { call, takeLatest, put } from 'redux-saga/effects'
import API from '../../services/service'
import { reasonCodes, sendPlaybackEvent } from '../modules/tracks'
import { ADD_ERROR, HANDLE_ERROR, toggleSkipOnError, removeError, addGeneralErrorReport, openModal } from '../modules/modal'
import { setPlayerControlsState, setPlayerState} from '../modules/player'

// Dealing with some circular dependencies here.

function * showErrorModal (action) {
  const { ref, autoSkipOnError, report, source, continuePlayback, path } = action;
  yield put(addGeneralErrorReport({ref, report, source, path}))
  yield put(openModal())
  if (autoSkipOnError) {
    // make song skip past this error
    yield put(toggleSkipOnError())
  } else {
    const idx = (ref === 'audioExpired') ? 6 : 9;
    const reason = reasonCodes.stop[idx];
    if (!continuePlayback) {
      yield put(sendPlaybackEvent('stop', reason))
      yield put(setPlayerState('paused'))
      yield put(setPlayerControlsState('paused'))
    }
  }
}

function * handleErrorSaga (action) {
  const { option, source } = action;
  if (option.action) {
    // user has selected to playOnThisDevice when presented with concurrency issue
    yield call(API.request, source + option.uri, 'post', {})
    yield put(removeError())
    yield put(setPlayerState('playing'))
    yield put(setPlayerControlsState('playing'))
  }
}

function * errorSaga () {
  yield takeLatest(ADD_ERROR, showErrorModal)
  yield takeLatest(HANDLE_ERROR, handleErrorSaga)
}

export default errorSaga
