import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import gt from 'lodash/get'
import API from '../../services/service'
import {setPlayable} from '../modules/playable'
import { loadChildNode, updateNodeTrack, SET_CHILD_NODE } from '../modules/music'
import { addError } from '../modules/modal'
import querystring from 'querystring'
import { SEND_PLAYBACK_EVENT, LOAD_TRACK_DEFINITION } from '../modules/tracks'
import { writePlaybackEventReport, getTrackEndpoint, noha } from '../../lib/utils'

const getNode = (state, node) => state.music.nodes[node]

function * sendPlaybackEventReport (action) {
  let source;
  try {
    const { event, reason, values } = action;
    const { URI, body } = yield writePlaybackEventReport(event, reason, values)
    source = URI;

    yield API.request(URI, 'post', body)
  } catch (e) {
    console.warn('Error sending playbackEventReport', e)

    // Authorization errors
    if (e.status === 401 || e.status === 403) {
      yield API.deleteToken()
      return
    }

    const { generalErrorReports, result } = gt(e, 'data', {})

    if (typeof result === 'string') {
      const report = gt(generalErrorReports, result.replace(/#/g, ''), false)

      if (report) {
        yield put(addError({ ref: 'playbackError', autoSkipOnError: false, report, source }))
        return
      }
    }

    console.warn('Unhandled error', e)
  }
}

function * loadTrackDefinition (action) {
  const { node, uri, currentPath, trackDef, track_def, playable, nextTrackChunk } = action;
  try {
    const { audio, duration } = trackDef;
    const expires = new Date(audio.expires)
    if (Date.now() + duration >= expires.getTime()) {
      const endpoint = yield getTrackEndpoint(uri, currentPath);
      const { data } = yield API.request(endpoint, 'get');
      const trackInstRef = noha(data.result);
      const trackDefRef = noha(data.trackInstances[trackInstRef].trackDefinition);
      const trackDefinition = data.trackDefinitions[trackDefRef];
      yield put(updateNodeTrack({node, track_def, trackDefinition})) // only updateNodeTrack if expiring
    }
    yield put(setPlayable(node, playable, nextTrackChunk))
  } catch (e) {
    console.warn(`Error loading track definition for ${uri}`, e)
    if(e.status === 401 || e.status === 403) API.deleteToken()
  }
}

function * setChildNode (action) {
  const { node, playable, indexWithinChunk } = action.payload;
  const existingNode = yield(select(getNode, node, indexWithinChunk))
  if(!existingNode) yield put(loadChildNode(node))
  yield put(setPlayable(node, playable, indexWithinChunk))
}

function * registerPathChange (action) {
  try {
    const { pathname, search, hash } = action.payload.location
    const { indexWithinChunk, ...rest } = querystring.parse(search.replace(/^\?+/,''));
    if (API.loggedIn() && /^\/?playback(\/|$)/.test(pathname)) {
      let fullPath = pathname.replace(/^\/?playback\/*/, '/');
      if (search && Object.keys(rest).length) {
        fullPath = decodeURIComponent(fullPath.replace(/\/*$/,'/') + search);
      }
      const node = fullPath.replace(/\/$/, '')
      const existingNode = yield(select(getNode, node))
      if(!existingNode) {
        yield put(loadChildNode(node))
      }
      yield put(setPlayable(node, hash, indexWithinChunk))
    }
  } catch (e) {
    console.warn(`Error registering for path change ${e.message}`, e)
  }
}

function * trackSaga () {
  yield takeLatest(SET_CHILD_NODE, setChildNode)
  yield takeEvery(LOAD_TRACK_DEFINITION, loadTrackDefinition)
  yield takeEvery(SEND_PLAYBACK_EVENT, sendPlaybackEventReport)
  yield takeLatest('@@router/LOCATION_CHANGE', registerPathChange)
}

export default trackSaga
