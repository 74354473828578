import React, { Component } from 'react'
import Home from '../Home/Home'
import { connect } from 'react-redux'
import { getMainCatalogData } from '../../lib/selectors/node_selectors'

import Space from '../../lib/reactv-redux/SpaceRedux'
import PageLoading from '../../components/PageLoading';

const mapStateToProps = (state, props) => ({
  loading: state.search.loading,
  catalog: state.search.results && getMainCatalogData(state)
})

const HomeComponent = Space(Home);

class SearchResult extends Component {
  render() {
    const { focused, catalog, loading, gridMenuId, changeFocus } = this.props;
    if (loading) {
      return (<PageLoading className='results-loading'/>)
    } else if (catalog && catalog.itemsData && catalog.itemsData.length) {
      return (
        <div className='search-result-wrapper'>
          <HomeComponent
            data={catalog.itemsData}
            focused={focused}
            gridMenuId={gridMenuId}
            onFarLeft={changeFocus('search:atoz')}
            slots={2}
            menuid='searchResult' // cannot be name of existing menu in state.menus
            onFocusItem={gridMenuId} // first highlighted item in Home Space
            changeFocus={changeFocus} // needed to make nav to topnav work
          />
        </div>
      )
    } else {
      return null
    }
  }
}

export default connect(mapStateToProps)(SearchResult)
