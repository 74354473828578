import React from 'react'
import cx from 'classnames'
import AmazonMusicLogo from '../../assets/images/AmazonMusicLoadingLogoWhite.svg'
import PageLoading from '../../components/PageLoading/PageLoading'

const Linking = ({user_code, verification_uri, className}) => {
  if (!user_code) {
    return (
      <div className={cx(className, 'Page')}>
        <img alt='amazon-music-logo' className='amazon-logo amazon-logo--centered' src={AmazonMusicLogo}/>
        <PageLoading className='disableMargin'/>
      </div>
    )
  }
  return (
    <div className={cx(className, 'Page')}>
      <img alt='amazon-music-logo' className='amazon-logo' src={AmazonMusicLogo}/>
      <div className='headline'>ACTIVATE</div>
      <label className='text-info text-link'>1. Visit <a href={verification_uri || 'https://amazon.com/code'} target="_blank" rel="noopener noreferrer">amazon.com/code</a> on your computer or mobile device</label>
      <label className='text-info'>2. Enter the following code</label>
      <div className='code'>
        <label>{user_code}</label>
      </div>
    </div>
  )
}
export default Linking
