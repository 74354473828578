import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import gt from 'lodash/get';

let Bugsnag = null;
let isInit = false;
const { REACT_APP_BUGSNAG_KEY, REACT_APP_AMAZON_MUSIC_VERSION } = process.env;
/**
 * Initialize the error reporting library
 * @param key {string} Api key used by the error reporting library
 * @param appVersion {string} App version number, used to group errors
 * @param options Configuration options passed to the error reporting library
 *
 */
export function init (key = REACT_APP_BUGSNAG_KEY, appVersion = REACT_APP_AMAZON_MUSIC_VERSION, options = {}) {
  if (isInit) { throw new Error('init can only be run once'); }
  isInit = true;

  Bugsnag = bugsnag({
    apiKey: key,
    appVersion: appVersion,
    notifyHandler: 'xhr',
    beforeSend: (report) => { console.info('Sending Bugsnag report:', report); }
  });
  Bugsnag.use(bugsnagReact, React);

  console.log('error-reporter', 'init', arguments);
  // bugsnag will only send the first 10 events from a page
  // see Bugsnag.refresh() for more information
  return Bugsnag;
}

/**
 * Add extra tags
 * @param tags An object with the new tags to be included
 */
export function addTags (tags) {
  if (Bugsnag) { Bugsnag.metaData = { ...Bugsnag.metaData, ...tags }; } else { console.log('error-reporter', 'addTags', tags); }
}

/**
 * Manually log an exception
 *
 * @param {error} ex An exception to be logged
 */
export function captureException (message) {
  const globalObj = window ? window : navigator;
  const clientInformation = gt(globalObj, 'clientInformation', null);
  const opts = {
    user: {
      connectionType: gt(clientInformation, 'connection.effectiveType', null),
      platform: gt(clientInformation, 'platform', null),
      userAgent: gt(clientInformation, 'userAgent', null)
    },
    context: gt(globalObj, 'location.pathname', null)
  };
  try {
    if (Bugsnag) {
      Bugsnag.notify(message, opts);
      console.warn('captureException:', message);
    } else {
      console.log('error-reporter', 'capturedException', message, opts);
    }
  } catch (e) {
    console.error('Error sending a Bugsnag report', e.message);
  }
}

/**
 * Manually log a message
 * @param {string} msg A plain text message to be captured in Bugsnag
 * @param level The log level associated with this event. Default: error
 */
export function captureMessage (name, msg, data, level = 'error') {
  if (Bugsnag) {
    Bugsnag.notify(
      new Error(name),
      {
        metaData: {msg, data},
        severity: level
      });
  }

  console.log('error-reporter', 'captureMessage', level);
}

export default {
  init,
  captureException,
  captureMessage,
  addTags
};
