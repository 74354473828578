import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import './SlotMenu.scss'
import { isEquivalent } from '../../../utils'

class SlotMenuItemRenderer extends React.Component {
  static propTypes = {
    render: PropTypes.func.isRequired,
    focused: PropTypes.bool.isRequired,
    item: PropTypes.object
  }


  render () {
    const { render, ...rest} = this.props
    return render({...rest})
  }
}

class SlotMenu extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    renderItem: PropTypes.func.isRequired,
    focused: PropTypes.bool.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    passRef: PropTypes.func,
    menuid: PropTypes.string.isRequired
  }
  static defaultProps = {
    focused: false,
    className: null,
    horizontal: false,
    passRef: null
  }

  shouldComponentUpdate (nextProps) {
    const shouldUpdate = (
      nextProps.focused !== this.props.focused
      || (nextProps.focused && nextProps.index !== this.props.index)
      || !isEquivalent(nextProps.data, this.props.data)
    )

    return shouldUpdate
  }

  render () {
    const {data, renderItem, className, index, focused, horizontal, style, passRef, allowExplicit, updateIndex, updateMenuState, clickHandler} = this.props
    return (
      <div className={cx('SlotMenu', className, {horizontal})} style={style} ref={passRef}>
        {data.map((item, currentIndex) => {
          return <SlotMenuItemRenderer
            key={currentIndex}
            item={item}
            idx={currentIndex}
            updateIndex={updateIndex}
            updateMenuState={updateMenuState}
            focused={focused && currentIndex === index}
            render={renderItem}
            clickHandler={clickHandler}
            allowExplicit={allowExplicit}
          />
        })}
      </div>
    )
  }
}
export default SlotMenu
