export const UPDATE_TOPNAV = 'topnav/UPDATE_TOPNAV'

export function updateTopnav(path) {
  return {
    type: UPDATE_TOPNAV,
    path
  }
}

const initialState = [
  {
    name: 'Browse',
    path: '/music/widescreen_catalog/',
  },
  {
    name: 'Recents',
    path: '/music/widescreen_recents/',
  },
  {
    name: 'My Music',
    path: '/music/widescreen_library/',
  },
  {
    name: 'Search',
    path: '/widescreen_search',
  },
  {
    name: 'Settings',
    path: '/settings',
  }
]

export default function topnavReducer(state = initialState, action) {
  switch(action.type) {
    case UPDATE_TOPNAV:
      const { path } = action;
      const newTopnav = initialState.slice(0);
      const settingsNav = newTopnav.pop();
      newTopnav.push({ name: 'Now Playing', path })
      newTopnav.push(settingsNav)
      return newTopnav
    default:
      return state
  }
}
