import React, { Component } from 'react'
import PlayerControls from './PlayerControls'
import Space from '../../lib/reactv-redux/SpaceRedux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setPlaybackEvent, sendPlaybackEvent, addTrackToQ, toggleShuffleStatus } from '../../store/modules/tracks'
import { withRouter } from 'react-router'
import { setCurrentTime, setPlayerState, onStarted, setProgressBarTime, setPlayerControlsState } from '../../store/modules/player'
import { getTrackContainerChunkDescription } from '../../pages/Playback/selectors'
import $badger from '../../lib/badger'
import KeyEvents from '../../lib/reactv-navigation/KeyEvents'
import { getAPIEndpoint, handleTrackPlayback, mergePath } from '../../lib/utils'
import { sendThumbs } from '../../store/modules/thumbs'
import { back } from '../../store/modules/nav'

const Keys = new KeyEvents()

const mapStateToProps = (state, ownProps) => ({
  shuffle: state.tracks.shuffle,
  currentTime: state.player.currentTime,
  playbackEnded: state.player.playbackEnded,
  playerControlsState: state.player.playerControlsState,
  location: state.router.location,
  playable: state.playable,
  chunk: getTrackContainerChunkDescription(state)
})

const mapDispatchToProps = (dispatch) => {
  const creators = bindActionCreators({
    back,
    setPlaybackEvent,
    sendPlaybackEvent,
    addTrackToQ,
    toggleShuffleStatus,
    onStarted,
    setCurrentTime,
    setPlayerState,
    setProgressBarTime,
    setPlayerControlsState,
    sendThumbs
  }, dispatch)
  return creators
}

class PlayerControlsContainer extends Component {
  constructor(props) {
    super(props);
    this.initialState = { thumbRating: 'neutral' }
    this.state = this.initialState;
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps.trackRating && prevState.thumbRating !== nextProps.trackRating.thumbRating) {
      return { thumbRating: nextProps.trackRating.thumbRating }
    }
    return null
  }

  reset() {
    this.setState(this.initialState);
  }

  componentDidMount () {
    this.playevent = Keys.subscribeTo('Play', () => {
      this.togglePlayState()
    })

    this.pauseevent = Keys.subscribeTo('Pause', () => {
      this.pause()
    })
  }

  componentWillUnmount () {
    this.playevent.unsubscribe()
    this.pauseevent.unsubscribe()
  }

  giveThumbs = (feedback) => {
    if (this.props.currentTime >= 0) {
      $badger.userActionMetricsHandler(`PlayerControlsThumbs`, {feedback})
      const { currentTime, trackRating: { ratingURI }, location, sendThumbs } = this.props;
      const clockTime = new Date();
      const trackRatingRequest = {}
      const thumbRating = (this.state.thumbRating !== feedback) ? feedback : 'neutral';
      const path = location.pathname.replace(/^\/playback\/?/,'/');
      if (ratingURI === 'rating/') {
        const temp = mergePath(path, this.props.self);
        trackRatingRequest.URI = mergePath(temp, ratingURI);
      } else {
        trackRatingRequest.URI = getAPIEndpoint(ratingURI, path);
      }
      trackRatingRequest.body = {
        thumbRating: thumbRating,
        trackPosition: Math.floor(currentTime * 1000) || 0,
        wallClockTime: clockTime.toISOString()
      }
      sendThumbs(trackRatingRequest)
    }
  }

  restart () {
    const { setCurrentTime, onStarted, playbackEnded, setPlayerState, setProgressBarTime } = this.props
    const restartFrom = 0
    $badger.userActionMetricsHandler(`PlayerControlsRestart`, {restartFrom})
    if (playbackEnded) onStarted()
    setProgressBarTime(restartFrom)
    setCurrentTime(restartFrom)
    setPlayerState('playing') // PLAY SONG
  }

  togglePlayState () {
    this.props.playerControlsState === 'playing' ? this.pause() : this.play()
  }

  pause () {
    if (this.props.playerControlsState !== 'playing') {
      return false
    }

    $badger.userActionMetricsHandler(`PlayerControlsTogglePlayState`, {from: 'playing', to: 'paused'})
    this.props.sendPlaybackEvent('stop', 'userPause')
    this.props.setPlayerControlsState('paused')
    this.props.setPlayerState('paused')
  }

  play () {
    if (this.props.playerControlsState === 'playing') {
      return false
    }

    $badger.userActionMetricsHandler(`PlayerControlsTogglePlayState`, {from: 'paused', to: 'playing'})
    this.props.sendPlaybackEvent('start', 'resume')
    this.props.setPlayerControlsState('playing')
    this.props.setPlayerState('playing')
  }

  backwardSkip () {
    const { currentTime, onStarted, playbackEnded, sendPlaybackEvent, setPlaybackEvent } = this.props
    sendPlaybackEvent('stop', 'userAdvanceBackward')
    setPlaybackEvent('start', 'userAdvanceBackward')
    if (currentTime > 2) this.restart()
    else {
      this.reset()
      handleTrackPlayback.call(this, 0)
    }
    if (playbackEnded) onStarted() // this is only true when repeat is true
  }

  forwardSkip () {
    this.reset()
    const { currentTime, onStarted, playbackEnded, sendPlaybackEvent, setPlaybackEvent } = this.props
    if (!playbackEnded) {
      sendPlaybackEvent('stop', 'userAdvance')
      setPlaybackEvent('start', 'userAdvance')
    }
    else {
      onStarted()
    }
    handleTrackPlayback.call(this, 1)
    $badger.userActionMetricsHandler(`PlayerControlsSkipCalled`, { currentTime })
  }

  toggleShuffle() {
    const { shuffle, addTrackToQ, toggleShuffleStatus, playable, chunk } = this.props;
    toggleShuffleStatus(!shuffle)
    if (!shuffle) {
      const track = chunk.trackInstances[playable.indexWithinChunk];
      addTrackToQ(track)
    }
  }

  render () {
    const { changeFocus, isFocused, location, onDown, playerControlsState, shuffle, trackRating, back } = this.props;
    const showThumbs = ((typeof trackRating === 'object') && (trackRating !== null));
    const showShuffle = (location.pathname.match(/playlist/,'')) ? true : false;
    return (
      <PlayerControls
        back={back}
        togglePlayState={this.togglePlayState.bind(this)}
        toggleShuffle={this.toggleShuffle.bind(this)}
        backwardSkip={this.backwardSkip.bind(this)}
        forwardSkip={this.forwardSkip.bind(this)}
        giveThumbs={this.giveThumbs.bind(this)}
        restart={this.restart.bind(this)}
        thumbRating={this.state.thumbRating}
        showShuffle={showShuffle}
        showThumbs={showThumbs}
        changeFocus={changeFocus}
        isFocused={isFocused}
        onDown={onDown}
        playerControlsState={playerControlsState}
        shuffle={shuffle}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Space(withRouter(PlayerControlsContainer)))
