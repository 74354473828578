import React from 'react'

const ThumbDown = () => (
  <svg viewBox="0 0 24 24">
    <path d="M3,15H7.41a.93.93,0,0,1,.82,1.39l-.15.27a6.19,6.19,0,0,0-.8,3.22,8.38,8.38,0,0,0,.42,2.21,1.33,1.33,0,0,0,2.55-.1l.36-1.44A7.33,7.33,0,0,1,12,17.76l2.29-2.88A2.39,2.39,0,0,1,16.13,14a.87.87,0,0,0,.87-.87V3a1,1,0,0,0-1-1H5.77A2,2,0,0,0,3.86,3.41l-2.77,9A2,2,0,0,0,3,15Z"/>
    <path opacity="0.5" d="M19,1h3a1,1,0,0,1,1,1V14a1,1,0,0,1-1,1H19a1,1,0,0,1-1-1V2A1,1,0,0,1,19,1Z"/>
  </svg>
)

export default ThumbDown
