import React from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { Trans } from 'react-i18next'
import createDebug from 'debug'
import HomeMenu from '../../components/HomeMenu'
import ListMenu, { calculateOffsetHeight } from '../../lib/reactv-redux/SlotMenuRedux'
import EmptyPage from '../../components/EmptyPage'
import { isFunction } from '../../lib/utils'
import { ScrollButton, ScrollButtonPlaceholder, ScrollButtons } from '../../components/MouseNavigation'
import './Home.scss'

const debug = createDebug('app:Home')

export const HomeGridContext = React.createContext({
  onFocus: () => {
    console.info('Focused on home menu item')
  },
})

const renderMenu = (onFarLeft) => (
  ({ item, ...rest }) => (
    <HomeMenu
      itemDescription={item}
      menuid={`homemenu:${window && window.location && window.location.pathname}:${item.itemLabel}`}
      {...rest}
      onFarLeft={onFarLeft}
    />
  )
)

const calculateStyle = (currentState, newState, ref) => {
  let offset
  if (newState.index > currentState.index && newState.slotIndex === currentState.slotIndex) {
    offset = calculateOffsetHeight(ref, newState.index, newState.slotIndex)
    return { transform: `translateY(-${offset}px)` }
  } else if (newState.index < currentState.index && currentState.slotIndex === 0) {
    offset = calculateOffsetHeight(ref, newState.index, newState.slotIndex)
    return { transform: `translateY(-${offset}px)` }
  } else {
    console.info('nothing moved, returning null')
    return null
  }
}

class Home extends React.Component {

  constructor(p) {
    super(p)
    this.state = {
      menuState: {
        index: 0, slotIndex: 0, max: p.data && p.data.length ? p.data.length -1 : 0
      }
    }
  }

  componentDidMount() {
    const { isFocused, changeFocus, gridMenuId } = this.props
    if (isFocused(gridMenuId) || isFocused('topnav')) {
      return
    }

    changeFocus('topnav')()
  }

  handleMenuState(mState) {
    debug('handleMenuState', mState,  this.state)
    if(this.state.menuState.index !== mState.index) {
      debug('Updating Menu State')
      const menuState = Object.assign({}, this.state.menuState, {index: mState.index})
      this.setState({menuState})
    }
  }

  setModifiers ({ increment, decrement }) {
    console.info('setting modifiers')
    this.__incrementer = increment
    this.__decrementer = decrement
  }

  increment () {
    if (isFunction(this.__incrementer)) this.__incrementer()
    else console.warn('Incrementer is not a function', this.__incrementer)
  }

  decrement () {
    if (isFunction(this.__decrementer)) this.__decrementer()
    else console.warn('Decrementer is not a function', this.__decrementer)
  }
  scrollButtons() {
    const {focused, isFocused, gridMenuId} = this.props
    const hasUp = this.state.menuState.index > 0
    const hasDown = this.state.menuState.index < this.state.menuState.max
    debug(`hasUp: ${hasUp} hasDown: ${hasDown}`, this.state.menuState)
    if(focused && isFocused(gridMenuId)) {
      return (
        <ScrollButtons className="scrolledButtons">
          {hasDown ? (<ScrollButton onClick={this.increment.bind(this)}><FiChevronDown size={'2em'}/></ScrollButton>) : (<ScrollButtonPlaceholder />)}
          {hasUp ? (<ScrollButton onClick={this.decrement.bind(this)}><FiChevronUp size={'2em'}/></ScrollButton>) : (<ScrollButtonPlaceholder />)}
        </ScrollButtons>
      )
    }
  }
  render () {
    const { focused, data, isFocused, changeFocus, pathname, gridMenuId, slots, onFarLeft } = this.props
    if (!data || data.length === 0) {
      let message = <Trans i18nKey='with-id[.]nothing-found'>Nothing found...</Trans>
      if (pathname.includes('/music/widescreen_recents')) {
        message = <Trans i18nKey='with-id[.]empty-recent'>You have not listened to any music. When you do, <br />your music will appear here.</Trans>
      }

      if (pathname.includes('/music/widescreen_library')) {
        message = <Trans i18nKey='with-id[.]empty-library'>You have not added any music to your library. When you do, <br />your music will appear here.</Trans>
      }

      return <EmptyPage message={message} />
    }
    return (
      <div className='Home-scrollable'>
        <HomeGridContext.Provider value={{ onFocus: changeFocus(gridMenuId), foo: 'bar' }}>
          <ListMenu
            onChange={this.handleMenuState.bind(this)}
            slots={slots}
            data={data}
            modifiers={this.setModifiers.bind(this)}
            renderItem={renderMenu(onFarLeft)}
            menuid={gridMenuId}
            focused={focused && isFocused(gridMenuId)}
            onUp={changeFocus('topnav')}
            calculateStyle={calculateStyle}
          />
        </HomeGridContext.Provider>
        {this.scrollButtons()}
      </div>
    )
  }
}


export default Home
