import config from '../../config'

export const SET_LNG = 'LNG/SET_LNG'

const initialState = {
  code: config.default_language
}

export default (state = initialState, action) => {
  switch(action.type) {
    case SET_LNG:
      return { code: action.code }
    default:
      return state
  }
}
