import styled from 'styled-components'

export default styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
`