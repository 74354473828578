import styled from 'styled-components'

export default styled.button`
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: white;
  font-size: 1em;
  margin: 0 0.5rem;
  width: 6.875rem;
  border: none;
  border-bottom: 0.25rem solid #61beeb;
  outline: 0 !important;
  box-sizing: border-box;
  &:hover {
    background-image: linear-gradient(to top, #61beeb, #3f86c3);
  }
`