export const SET_AUTH_DATA = 'AUTH/SET_AUTH_DATA'
export const REQUEST_ACCESS_TOKEN = 'AUTH/REQUEST_ACCESS_TOKEN'
export const CLEAR_AUTH_DATA = 'AUTH/CLEAR_AUTH_DATA'
export const TRACK_ACCESS_TOKEN = 'AUTH/TRACK_ACCESS_TOKEN'
export const SET_TEMPORARY_REFRESH_TOKEN = 'AUTH/SET_TEMPORARY_REFRESH_TOKEN'
export const CLEAR_TEMPORARY_REFRESH_TOKEN = 'AUTH/CLEAR_TEMPORARY_REFRESH_TOKEN'

const initialState = {
  access_token: null,
  refresh_token: null,
  token_type: 'bearer',
  expires_in: null,
  expires_at: null
}

export function requestAccessToken() {
  return { type: REQUEST_ACCESS_TOKEN }
}

export default function authReducer(state = initialState, action) {
  switch(action.type) {
    case CLEAR_TEMPORARY_REFRESH_TOKEN:
      return Object.assign({}, state, { temp_refresh_token: null })
    case SET_TEMPORARY_REFRESH_TOKEN:
      const { temp_refresh_token } = action;
      return Object.assign({}, state, { temp_refresh_token })
    case SET_AUTH_DATA:
      console.log('got auth data, setting access token')
      const expires_at = new Date()
      expires_at.setSeconds(expires_at.getSeconds() + action.payload.expires_in)
      return Object.assign({}, action.payload, {expires_at})
    case CLEAR_AUTH_DATA:
      return initialState
    default:
      return state
  }
}
