import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from '../../store'
import Routes from '../Routes'
import Player from '../../components/Player'
import './App.scss'
import Settings from '../../components/Settings'
import Modal from '../../components/Modal'
import { back } from '../../store/modules/nav'
import KeyEvents from '../../lib/reactv-navigation/KeyEvents'

const Keys = new KeyEvents()

const globalHistoryBack = () => {
  try {
    if (store.getState().nav.showSettings) {
      return
    }
  } catch (_) {}
  back()(store.dispatch, store.getState)
}

Keys.subscribeTo('Back', globalHistoryBack)
Keys.subscribeTo('Return', globalHistoryBack)

const App = () => (
  <>
    <Provider store={store}>
      <>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
        <Settings />
        <Modal/>
        <Player/>
      </>
    </Provider>
  </>
)

export default App
