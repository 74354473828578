import React from 'react'
import cx from 'classnames'
import css from './PlaylistMenuItem.scss'
import PlayIconRound from '../../assets/images/icon/play-icon-round.js'
import { transcodeImg } from '../../lib/utils'

const PlaylistMenuItem = ({item: {itemLabel, image, subtitle, isExplicit}, focused, allowExplicit, clickHandler, idx, updateIndex}) => (
  <div className={cx(css.PlaylistMenuItem, {focused, isExplicit: (`${isExplicit}` === 'true' && !allowExplicit)})}
    onMouseOver={() => {
      updateIndex(idx)
    }}
    onClick={clickHandler}
  >
    {focused && <div className="image"><PlayIconRound /></div>}
    {image && <img src={transcodeImg(image.uri)} height={60} width={60} alt={itemLabel} className='image' style={focused ? { position: 'absolute', opacity: 0 } : {}} />}
    <div className='info'>
      <label className='title'>{itemLabel}</label>
      <label className='subtitle'>{subtitle}</label>
    </div>
  </div>
)

export default PlaylistMenuItem
