import {isNormalInteger} from '../../lib/utils'

export const SET_CURRENT_PLAYABLE = 'PLAYABLES/SET_CURRENT_PLAYABLE'
export const TOGGLE_ALLOW_EXPLICIT = 'PLAYABLES/TOGGLE_ALLOW_EXPLICIT'

export function toggleAllowExplicit() {
  return {
    type: TOGGLE_ALLOW_EXPLICIT
  }
}

export function setPlayable(node, chunk, indexWithinChunk = 0) {
  return {
    type: SET_CURRENT_PLAYABLE,
    node, chunk, indexWithinChunk
  }
}

const initialState = {
  node: null, chunk: null, index: 0, indexWithinChunk: 0, allowExplicit: true
}


export default function playableReducer(state = initialState, action ) {
  switch(action.type) {
    case SET_CURRENT_PLAYABLE:
      const indexWithinChunk = isNormalInteger(action.indexWithinChunk) ?  action.indexWithinChunk : 0
      return Object.assign({}, state, {node: action.node, chunk: action.chunk, indexWithinChunk })
    case TOGGLE_ALLOW_EXPLICIT:
      return Object.assign({}, initialState, { allowExplicit: !state.allowExplicit })
    default:
      return state
  }
}
