export const ACTIVATE_MOUSE = 'mouse/ACTIVATE_MOUSE'
export const DEACTIVATE_MOUSE = 'mouse/DEACTIVATE_MOUSE'
export const activateMouse = () => ({type: ACTIVATE_MOUSE})
export const deactivateMouse = () => ({type: DEACTIVATE_MOUSE})
const initialState  = {
  mouseActive: false
}

export default function mouseReducer( state = initialState, action) {
  if(action.type === ACTIVATE_MOUSE && !state.mouseActive) return {mouseActive: true}
  else if (action.type === DEACTIVATE_MOUSE && state.mouseActive === true) return {mouseActive: false}
  else return state
}
