export const SET_UPSALE_MSG = 'UPSALE/SET_MSG'

export function setUpsaleMessage(report) {
  return {
    type: SET_UPSALE_MSG,
    report
  }
}

const initialState = {}

export default (state = initialState, action) => {
  switch(action.type) {
    case SET_UPSALE_MSG:
      return action.report
    default:
      return state
  }
}
