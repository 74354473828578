import React from 'react'
import Space from '../../lib/reactv-redux/SpaceRedux'
import PlayerControls from '../PlayerControls'
import style from './TrackInfo.scss'
import { transcodeImg } from '../../lib/utils'

const TrackInfo = ({title, artist, album, image, isFocused, onDown, self, trackRating}) => {
  const artistName = artist && artist.name
  const albumName = album && album.name
  const trackDeetsSubtitle = (artistName && albumName) ? (artistName + ' • ' + albumName) : (artistName || albumName)

  return (
    <div className={style.TrackInfo}>
      {image && (<img src={transcodeImg(image.uri).replace(/SX[0-9]+_SY[0-9]+_\.jpg$/,'SX1000_SY1000_.jpg')} alt={title} />)}
      <div className='TrackDeets'>
        <div className='titleSection'>
          <label className='title'>{title}</label>
          {(artistName || albumName) && (
            <label className='subtitle'>{trackDeetsSubtitle}</label>
          )}
        </div>
        <PlayerControls
          menuid='playback:playercontrols'
          focused={isFocused('playback:playercontrols')}
          onFocusItem={'playback:playercontrols:pause'}
          onDown={onDown}
          self={self}
          trackRating={trackRating}
        />
      </div>
    </div>
  )
}

export default Space(TrackInfo)
