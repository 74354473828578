import React from 'react'

const MenuComposer = (MenuComponent, InnerComponent) => {
  class MenuWrapper extends React.Component {
    render() {
      const {changeFocus, isFocused, updateMenu, entryFocus } = this.props;
      return (
        <>
          <MenuComponent
            menuid='topnav'
            mid='topnav'
            focused={isFocused('topnav')}
            onDown={(entryFocus) ? changeFocus(entryFocus) : null}
            onRight={() => updateMenu('topnav', { index: 0 })}
            updateMenu={updateMenu}
            onMouseOver={changeFocus('topnav')}
          />
          <InnerComponent {...this.props} />
        </>
      )
    }
  }

  return MenuWrapper
}

export default MenuComposer
