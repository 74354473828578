import { createSelector } from 'reselect'
import { noha } from '../utils'
import config from '../../config/index'
import { mergePath } from '../utils'
import up from 'url-parse'
import gt from 'lodash/get'

const getNodes = state => state.music.nodes
const getLocation = state => state.router.location
const getNavigationNodeSummary = (state, ownProps) => {
  let navigationNodeSummary = gt(ownProps, 'itemDescription.navigationNodeSummary', null)
  if (!navigationNodeSummary) navigationNodeSummary = ownProps.navigationNodeSummary
  return navigationNodeSummary
}
const getCustomKey = (state, _key) => (_key && typeof(_key) !== 'object') ? _key : null

const getKeySelector = createSelector([getCustomKey, getLocation], (_key, location) => {
  const { pathname } = location;
  let key = (_key) ? _key : pathname.replace(/^\/(list|music)\/*/, '/')
  return ((key === '' || key === '/') && /^\/?music(\/|$)/.test(pathname))
    ? config.music.browse_node : key === '' ? '/' : key
})
export const getNodeSegmentSelector = createSelector([getNodes, getKeySelector], (nodes, key) => nodes[key])
export const getItemDescriptionsSelectors = createSelector([getNodeSegmentSelector], (node) => (node ? node['itemDescriptions'] : null))
export const getNavigationNodeDescriptionsSelector = createSelector([getNodeSegmentSelector], (node) => (node ? node['navigationNodeDescriptions'] : null))
export const getNavigationNodeSummariesSelector = createSelector([getNodeSegmentSelector], (node) => (node ? node['navigationNodeSummaries'] : null))
export const getPlayableSelector = createSelector([getNodeSegmentSelector], (node) => (node ? node['playables'] : null))
export const getResult = createSelector([getNodeSegmentSelector], (node) => (node ? node['result'] : null))
export const getMenuIDsSelector = createSelector([getItemDescriptionsSelectors], (items) => {
  if (items) {
    let menuIDs = [];
    for (let key in items) {
      menuIDs.push(`homemenu:${window && window.location && window.location.pathname}:${items[key].navigationNodeSummary}`)
    }
    return menuIDs
  }
  else return
})
export const getNavigationNodeSummarySelector = createSelector([getNavigationNodeSummary, getNavigationNodeSummariesSelector], (summary, summaries) => {
  if (!summary || !summaries) return null
  return summaries[noha(summary)]
})
export const getChildItemPathname = createSelector(
  [getNavigationNodeSummarySelector, getKeySelector], (summary, key) => {
    if (!summary) return null
    const path = mergePath(key, summary.description)
    const { pathname } = up(path)
    return pathname
  }
)
export const makeNavigationDescriptionSelector = () => {
  return createSelector(
    [
      getNavigationNodeSummarySelector,
      getNavigationNodeDescriptionsSelector,
      getChildItemPathname,
      getKeySelector,
      getNodes
    ],
    (summary, descs, childPath, key, nodes) => {
      if (!summary) return null
      if (summary.description.indexOf('#') === 0) {
        //TODO: this is not right, we need a path to get this back.....
        if (key === '/widescreen_search' && nodes[key]) {
          const parentNode = nodes[key];
          const currentNode_desc = noha(summary.description);
          const navigationNodeDescription = Object.assign({}, descs[currentNode_desc])
          const { items } = descs[currentNode_desc];
          navigationNodeDescription.itemsData = items.map(item => {
            let result = parentNode.itemDescriptions[noha(item)]
            if (!result) {
              console.error('Error: no result',parentNode)
              return null
            }
            result.ref = item.replace(/_item/,'_desc');
            if (parentNode.playables && result.playable) result.isExplicit = parentNode.playables[noha(result.playable)].isExplicit;
            return result
          })
          return navigationNodeDescription
        } else return descs[noha(summary.description)]
      } else {
        if(nodes[childPath]) {
          const node = nodes[childPath]
          const {itemDescriptions, navigationNodeDescriptions, navigationNodeSummaries, result} = node
          const { playables } = node;
          return parseDescription(itemDescriptions, navigationNodeDescriptions, navigationNodeSummaries, result, playables)
        } else {
          return childPath
        }
      }
    })
}
export const makeGetCatalogData = () => {
  return createSelector(
    [getItemDescriptionsSelectors, getNavigationNodeDescriptionsSelector, getNavigationNodeSummariesSelector, getResult, getPlayableSelector],
    (itemDescriptions, navigationNodeDescriptions, navigationNodeSummaries, result, playables) => {
      return parseDescription(itemDescriptions, navigationNodeDescriptions, navigationNodeSummaries, result, playables)
    }
  )
}
export const getCatalogData = createSelector(
  [getItemDescriptionsSelectors, getNavigationNodeDescriptionsSelector, getNavigationNodeSummariesSelector, getResult, getPlayableSelector],
  (itemDescriptions, navigationNodeDescriptions, navigationNodeSummaries, result, playables) => {
    return parseDescription(itemDescriptions, navigationNodeDescriptions, navigationNodeSummaries, result, playables)
  }
)
export const getMainCatalogData = createSelector(
  [getItemDescriptionsSelectors, getNavigationNodeDescriptionsSelector, getResult],
  (itemDescriptions, navigationNodeDescriptions, result) => {
    if (!result || !navigationNodeDescriptions) return
    let desc = {}
    desc.itemsData = navigationNodeDescriptions[noha(result)].items.map(item => {
      let itemDesc = itemDescriptions[noha(item)]
      itemDesc.ref = item
      return itemDesc
    })
    return desc
  }
)
export const getChildItemDescriptionsSelector = createSelector(
  [getNavigationNodeSummarySelector, getItemDescriptionsSelectors, getNavigationNodeDescriptionsSelector, getChildItemPathname, getKeySelector, getNodes],
  (summary, itemDesc, descs, childPath, key, nodes) => {
    if (!summary) return null

    if (key === '/widescreen_search') {
      const currentNode = summary.description;
      const { items } = descs[noha(currentNode)];
      const itemDescriptions = {};
      items.map(item => {
        const item_desc = item.replace(/_item$/,'_desc');
        itemDescriptions[noha(item_desc)] = itemDesc[noha(item)]
        return null
      })
      return itemDescriptions
    } else {
      if (nodes[childPath]) return nodes[childPath].itemDescriptions
      else return null
    }
  }
)
export const getChildItemPlayablesSelector = createSelector(
  [getNavigationNodeSummarySelector, getNavigationNodeDescriptionsSelector, getChildItemPathname, getKeySelector, getNodes],
  (summary, descs, childPath, key, nodes) => {
    if (!summary) return null
    if (key === "/widescreen_search") {
      const parentNode = nodes[key];
      const currentNode_desc = noha(summary.description);
      const { items } = descs[currentNode_desc];
      const playables = {}
      items.map(item => {
        let result = parentNode.itemDescriptions[noha(item)]
        if (!result) {
          console.error('Error: no result',parentNode)
          return null
        }

        if (result.playable) {
          result.ref = item.replace(/_item$/,'_desc');
          const { playable } = result;
          const current = noha(playable);
          playables[current] = parentNode.playables[current]
        }

        return null
      })
      return playables
    } else {
      if(nodes[childPath]) return nodes[childPath].playables
      else return null
    }
  }
)
export const getChildNavigationNodeSummariesSelector = createSelector(
  [getNodes, getChildItemPathname],
  (nodes, childPath) => {
    if (nodes[childPath]) return nodes[childPath].navigationNodeSummaries
    else return null
  }
)
const parseDescription = (itemDescriptions, navigationNodeDescriptions, navigationNodeSummaries, result, playables) => {
  if (!result || !navigationNodeDescriptions) return
  let currentNavigationNode = result
  let desc = Object.assign({}, navigationNodeDescriptions[noha(currentNavigationNode)]) // get a copy
  if (!desc.items) return
  if (currentNavigationNode === '#search_desc') {
    currentNavigationNode = document.location.hash
    desc = Object.assign({}, navigationNodeDescriptions[noha(currentNavigationNode)]) // get a copy
  }
  desc.title = navigationNodeSummaries[noha(desc.summary)].title;
  let validItems = desc.items.filter(item => {
    const itemDescription = itemDescriptions[noha(item)];
    const { navigationNodeSummary, playable } = itemDescription;
    // it's a playable node
    if (playable && playable.length) return true
    // it's a navigational node: check if node has any items in it
    const nodeSummary = navigationNodeSummaries[noha(navigationNodeSummary)];
    const navigational = (nodeSummary && nodeSummary.numItemsOfInterest > 0);
    const sandBoxItem = (!nodeSummary || (!nodeSummary.playable && !nodeSummary.numItemsOfInterest))
    return (navigational || sandBoxItem)
  })
  desc.itemsData = validItems.map(item => {
    let itemDesc = itemDescriptions[noha(item)]
    itemDesc.ref = item
    if (playables && itemDesc.playable && itemDesc.playable.indexOf('#') === 0) itemDesc.isExplicit = playables[noha(itemDesc.playable)].isExplicit;
    return itemDesc
  })
  return desc
}
