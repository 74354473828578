import React from 'react'
import css from './AtoZMenu.scss'
import cx from 'classnames'
import includes from 'lodash/includes'
import { Trans } from 'react-i18next'

const AtoZMenu = ({menuItems, clicked, isFocused, menu: { index }, className, style, onEnter, onFocus, claimFocus, clickHandler}) => (
  <div className={cx(css.AtoZMenu, {[className]: className})} style={style}>
    <ul>
      {menuItems.map((letter, idx) => (
        <li
          onClick={clickHandler}
          onMouseOver={() => {
            onFocus()
            claimFocus(idx)
          }}
          key={`atoz:${letter}:idx`}
          className={cx({focused: isFocused(idx), clicked: includes(clicked, letter), 'wider': (letter === 'Space' || letter === 'Delete' || letter === 'Search')})}
        ><label><Trans>{letter}</Trans></label></li>
      ))}
    </ul>
  </div>
)
export default AtoZMenu
