import React from 'react'
import gt from 'lodash/get'
import SlotMenu from './SlotMenu'
import { KeyEvents } from '../../../reactv-redux/index'
import {isFunction} from '../../utils'
import PropTypes from 'prop-types'
import createDebug from 'debug'

const debug = createDebug('app:SlotMenu')

const keys = new KeyEvents()

class SlotMenuContainer extends React.Component {
  constructor (p) {
    super(p)
    this.state = {
      index: 0, slotIndex: 0, style: null
    }
    this.bindings = []
  }

  static propTypes = {
    onChange: PropTypes.func,
    onRight: PropTypes.func,
    onLeft: PropTypes.func,
    onUp: PropTypes.func,
    onDown: PropTypes.func,
    eventBinder: PropTypes.func,
    onFocusIndex: PropTypes.number,
    slots: PropTypes.number.isRequired,
    calculateStyle: PropTypes.func
  }
  static defaultProps = {
    onFocusIndex: 0, onFocusSlotIndex: 0
  }

  bind () {
    this._inc = keys.subscribeTo((this.props.horizontal ? 'Left' : 'Up'), () => {
      this.decrement()
    })
    this._dec = keys.subscribeTo((this.props.horizontal ? 'Right' : 'Down'), () => {
      this.increment()
    })
    if (this.props.horizontal) {
      if (this.props.onUp) this._offside1 = keys.subscribeTo('Up', this.props.onUp)
      if (this.props.onDown) this._offside2 = keys.subscribeTo('Down', this.props.onDown)
    }
    else {
      if (this.props.onRight) this._offside1 = keys.subscribeTo('Right', this.props.onRight)
      if (this.props.onLeft) this._offside2 = keys.subscribeTo('Left', this.props.onLeft)
    }
    this.click = keys.subscribeTo('Enter', this.clickHandler.bind(this))
  }
  clickHandler(e) {
    const fun = this.props.onEnter || this.props.onClick
    if (typeof fun === 'function') {
      this._tappedDelay = 0
      if (this.props.onClick) {
        try {
          const card = e.target
          if (card && card.classList.contains('focused')) {
            this._tappedDelay = 300

            const rect = card.getBoundingClientRect();
            const span = document.createElement('span');

            span.className = 'clicked-pulse';
            span.style.top = `${e.pageY - rect.top}px`;
            span.style.left = `${e.pageX - rect.left}px`;
            span.style.height = span.style.width = `${Math.max(
              rect.width,
              rect.height
            )}px`;
            card.appendChild(span);
          }
        } catch (_) {}
      }
      clearTimeout(this._tappedEmitter)
      this._tappedEmitter = setTimeout(() => fun(this.props.data[this.state.index], this.props.index), this._tappedDelay)
    }
  }
  unbind () {
    if (this._inc) this._inc.unsubscribe()
    if (this._dec) this._dec.unsubscribe()
    if (this._offside1) this._offside1.unsubscribe()
    if (this._offside2) this._offside2.unsubscribe()
    if (this.click) this.click.unsubscribe()
  }

  componentDidMount () {
    if (this.props.focused) this.bind()
    if(isFunction(this.props.modifiers)) {
      // console.info('setting modifiers')
      this.props.modifiers({
        increment: this.increment.bind(this),
        decrement: this.decrement.bind(this),
        updateMenuState: this.updateMenuState.bind(this),
        updateIndex: this.updateIndex.bind(this)
      })
    } else {
      // console.warn('no modifiers', this.props.modifiers)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
   // debug('getting derived state from props', nextProps, prevState)

    if (nextProps.mouseActive) {
      debug('ignore manipulating next focus')
      return null
    }

    if (prevState.redux_list_menu_focused) {
      if (!nextProps.focused) {
        debug('getting focus')
        return {
          redux_list_menu_focused: false
        }
      }

      debug('skip manipulating next focus')
      return null
    }

    if (nextProps.onFocusIndex === prevState.index) {
     // debug('just returning focus')
      return {
        redux_list_menu_focused: nextProps.focused
      }
    }

    if (nextProps.onFocusIndex < 0 || nextProps.onFocusIndex >= nextProps.data.length) {
      debug(`Got invalid onFocusIndex of ${nextProps.onFocusIndex} max: ${nextProps.data.length - 1}`)
      return {
        redux_list_menu_focused: true
      }
    }

    if (nextProps.onFocusSlotIndex < 0 || nextProps.onFocusSlotIndex >= nextProps.slots) {
      debug('got invalid slot index of ' + nextProps.onFocusSlotIndex + ' setting to 0')
      return {
        index: nextProps.onFocusIndex,
        redux_list_menu_focused: true,
        slotIndex: 0,
        style: null
      }
    }

    let focusSlot = nextProps.onFocusSlotIndex
    // Don't set slot 3 focused if only 2 items
    if (focusSlot >= nextProps.data.length) {
      focusSlot = nextProps.data.length - 1
    }

    debug(`got focus; returning derived state index:${prevState.index}/${nextProps.onFocusIndex}`)
    return {
      index: nextProps.onFocusIndex,
      redux_list_menu_focused: true,
      slotIndex: focusSlot,
      style: nextProps.style
    }
  }

  componentWillUnmount () {
    this.unbind()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.focused && !prevProps.focused) {
      // navigating to item
      console.info(`got focus on slot menu ${this.state.index}`)
      if (typeof this.props.onFocus === 'function') {
        this.props.onFocus(
          {index: this.state.index, slotIndex: this.state.slotIndex, style: this.state.style},
          {index: prevState.index, slotIndex: prevState.slotIndex, style: prevState.style},
          this._ref
        )
      }
      this.bind()
    }
    else if (!this.props.focused && prevProps.focused) {
      // navigated away from item
      if (typeof this.props.onBlur === 'function') {
        this.props.onBlur(
          {index: this.state.index, slotIndex: this.state.slotIndex, style: this.state.style},
          {index: prevState.index, slotIndex: prevState.slotIndex, style: prevState.style},
          this._ref
        )
      }
      this.unbind()
    }

    if (this.state.index !== prevState.index) {
      // navigating within item without losing focus
      if (typeof this.props.onChange === 'function') {
        this.props.onChange(
          {index: this.state.index, slotIndex: this.state.slotIndex, style: this.state.style, focused: this.props.focused},
          {index: prevState.index, slotIndex: prevState.slotIndex, style: prevState.style, focused: prevProps.focused},
          this._ref
        )
      }
    }
  }

  updateMenuState (index, slotIndex) {
    const change = {
      // not greater than data length
      index: Math.min(index, gt(this.props, 'data.length', 1) - 1),
      // not smaller than 0
      slotIndex: Math.max(slotIndex, 0),
    }
    if (typeof (this.props.calculateStyle) === 'function') {
      const newStyle = this.props.calculateStyle({
        index: this.state.index,
        slotIndex: this.state.slotIndex
      }, change, this._ref)
      if (newStyle !== null) {
        const updatedStyle = Object.assign({}, this.props.style || {}, newStyle)
        change.style = updatedStyle
      }
      if (newStyle === null) console.info('change', change)
    }

    this.setState(change)
  }

  updateIndex(index) {
    if(index === this.state.index) {
      console.info('Current index has not changed - bailing')
      return
    }
    console.info(`update index to index: ${index} currentIndex: ${this.state.index} currentSlot: ${this.state.slotIndex}`, index, this.state.index, this.state.slotIndex)
    const indexDelta = index - this.state.index
    console.info(`Index Delta: ${indexDelta}`)
    const slotIndex =  this.state.slotIndex + indexDelta
    console.warn(`Updating to index: ${index} slotIndex: ${slotIndex}`)
    this.updateMenuState(index, slotIndex)
  }

  increment () {
    if (this.state.index < this.props.data.length - 1) {
      const slotIndex = this.state.slotIndex < this.props.slots - 1 ? this.state.slotIndex + 1 : this.state.slotIndex
      this.updateMenuState(this.state.index + 1, slotIndex)
    } else if (this.props.horizontal && typeof this.props.onRight === 'function') {
      this.props.onRight()
    }
  }

  decrement () {
    if (this.state.index > 0) {
      const slotIndex = this.state.slotIndex > 0 ? this.state.slotIndex - 1 : this.state.slotIndex
      this.updateMenuState(this.state.index - 1, slotIndex)
    } else if (this.props.horizontal && typeof this.props.onLeft === 'function') {
      this.props.onLeft()
    } else if (this.props.onUp) {
      this.props.onUp()
    } else if (typeof this.props.onFarLeft === 'function'){
      this.props.onFarLeft()
    }
  }

  render () {
    const { passRef, ...rest} = this.props
    const referer = (r) => {
      this._ref = r
      if (typeof passRef === 'function') passRef(r)
    }
    return (<SlotMenu {...rest} {...this.state}
      style={this.state.style}
      passRef={referer}
      updateMenuState={this.updateMenuState.bind(this)}
      updateIndex={this.updateIndex.bind(this)}
      clickHandler={this.clickHandler.bind(this)}
    />)
  }
}

export default SlotMenuContainer
