import React from 'react'
import ReactHLS from '../ReactHLS/react-hls'
import d from 'debug'
const debug = d('app:player:eme')

debug("process.env.REACT_APP_HLSJS_DEBUG: %s", process.env.REACT_APP_HLSJS_DEBUG)
debug("process.env.REACT_APP_HLSJS_CONFIG: %s", process.env.REACT_APP_HLSJS_CONFIG)

const getHLSConfig = (force = {}) => {
  const debugHLSJS = (process.env.REACT_APP_HLSJS_DEBUG === 'true' || process.env.REACT_APP_HLSJS_DEBUG === true)
  if(process.env.REACT_APP_HLSJS_CONFIG) {
    try {
      const decoded = decodeURI(process.env.REACT_APP_HLSJS_CONFIG)
      const parsed = JSON.parse(decoded)
      return debugHLSJS ? {...parsed, debug: true, ...force} : {...parsed, ...force}
    } catch(e) {
      console.warn('unable to parse HLSJS_CONFIG')
    }
  }
  return {debug: debugHLSJS, ...force}
}


const MediaTagPlayer = ({ src, setPlayer, controls,videoProps,  ...rest }) => (
  <video src={src} {...videoProps} ref={setPlayer} controls={false} />
)

const EMEPLayer = ({ src, setPlayer, controls,  ...rest }) => { 
  const hlsConfig = getHLSConfig()
  debug("hlsConfig", hlsConfig)
  return (
  <ReactHLS
    hlsConfig={hlsConfig}
    url={src}
    controls={controls === true || controls === 'true'}
    ref={element => {
      const e = element ? element.refs.video : element
      setPlayer(e)
    }}
    {...rest}
  />
)
}
debug(`${( process.env.REACT_APP_HLSJS_USENATIVE === true ||  process.env.REACT_APP_HLSJS_USENATIVE === 'true' ) ? 'Native Player' :'EME Player'}`, process.env.REACT_APP_HLSJS_USENATIVE)
const Player = process.env.REACT_APP_HLSJS_USENATIVE === true ||  process.env.REACT_APP_HLSJS_USENATIVE === 'true' 
  ? MediaTagPlayer
  : EMEPLayer
export default Player
