export const STATIONS_ADD_PREV_TRACKPOINTER = 'stations/STATIONS_ADD_PREV_TRACKPOINTER'
export const STATIONS_REMOVE_PREV_TRACKPOINTER = 'stations/STATIONS_REMOVE_PREV_TRACKPOINTER'
export const STATIONS_TOGGLE_STATION_PLAYBACK = 'stations/STATIONS_TOGGLE_STATION_PLAYBACK'

export function addPrevTrackPointer(payload) {
  return {
    type: STATIONS_ADD_PREV_TRACKPOINTER,
    payload
  }
}
export function removePrevTrackPointer(payload) {
  return {
    type: STATIONS_REMOVE_PREV_TRACKPOINTER,
    payload
  }
}
export function toggleStationPlayback(payload) {
  return {
    type: STATIONS_TOGGLE_STATION_PLAYBACK,
    payload
  }
}

const initialState = {
  // TODO: change this to default false, and toggle on/off depending on playback
  stationPlayback: false,
  batchIdx: null,
  prevTrackPointer: []
}

export default function stationsReducer (state = initialState, action) {
  let batchIdx, prevTrackPointer;
  switch(action.type) {
    case STATIONS_ADD_PREV_TRACKPOINTER:
      prevTrackPointer = state.prevTrackPointer.slice(0).concat([action.payload])
      batchIdx = (state.batchIdx === null) ? 0 : state.batchIdx + 1;
      return Object.assign({}, state, { prevTrackPointer, batchIdx })
    case STATIONS_REMOVE_PREV_TRACKPOINTER:
      prevTrackPointer = state.prevTrackPointer.slice(0,-1)
      batchIdx = (state.batchIdx > 0) ? state.batchIdx - 1 : null;
      return Object.assign({}, state, { prevTrackPointer, batchIdx })
    case STATIONS_TOGGLE_STATION_PLAYBACK:
      return Object.assign({}, state, { stationPlayback: action.payload })
    default:
      return state
  }
}
