import React from 'react'
import AtoZMenu from '../../components/AtoZMenu'
import { alphabet } from '../../components/AtoZMenu/AtoZContainer'
import SearchResult from './SearchResult'
import css from './Search.scss'
import SearchIcon from '../../assets/images/icon/search-icon.js'

const Search = ({ term, focused, data, gridMenuId, onLetter, updateMenu, isFocused, changeFocus }) => {
  return (
    <div className={`searchPage ${css.Search}`}>
      <div className='search-info'>
        <div className='field'>
          <SearchIcon />
          <div className="search-form">
            <label>{term}</label>
            <span className='blinker' />
          </div>
        </div>
        <AtoZMenu
          menuid='search:atoz'
          mid='search:atoz'
          focused={focused && isFocused('search:atoz')}
          onEnter={onLetter}
          onFocus={changeFocus('search:atoz')}
          onUp={changeFocus('topnav')}
          onDown={(data && !data.itemDescriptions['_obj0']) ? changeFocus(gridMenuId) : null}
          onRight={() => updateMenu('search:atoz', { index: 0 })}
          onLeft={() => updateMenu('search:atoz', { index: alphabet.length - 1 })}
        />
      </div>
      <SearchResult
        gridMenuId={gridMenuId}
        changeFocus={changeFocus}
        focused={focused && isFocused(gridMenuId)}
      />
    </div>
  )
}

export default Search
