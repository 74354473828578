import React, { PureComponent } from 'react'
import Search from './Search'
import MainMenu, { MenuComposer } from '../../components/MainMenu'
import Space from '../../lib/reactv-redux/SpaceRedux'
import PageLoading from '../../components/PageLoading'
import { addLetterToSearchTerm, removeLetterFromSearchTerm } from '../../store/modules/search'
import { loadSearchNode, clearResults } from '../../store/modules/search'
import { connect } from 'react-redux'
import { push, replace } from '../../store/modules/nav'
import { uuid } from '../../lib/utils'
import { updateMenu } from '../../lib/reactv-redux/MenusReducer'
import { clearMenu } from '../../lib/reactv-redux/ReacTVReduxReducer'

const mapDispatchToProps = {
  clearMenu,
  removeLetterFromSearchTerm,
  addLetterToSearchTerm,
  push,
  replace,
  updateMenu,
  loadSearchNode,
  clearResults,
}
const mapStateToProps = (state) => ({
  loadList: state.search.loadList,
  term: state.search.term,
  results: state.search.results,
  menus: state.menus,
  pathname: state.router.location.pathname,
  showSettings: state.nav.showSettings,
  showModal: state.modal.showModal
})

const SearchComponent = Space(MenuComposer(MainMenu, Search));

class SearchContainer extends PureComponent {
  constructor(p) {
    super(p)
    this.key = 'WHAT_IS_THIS_MAGIC?'
  }

  componentWillUpdate(nextProps) {
    if (nextProps.results !== this.props.results) {
      this.key = uuid()
    }
  }

  onSubmit(value) {
    this.props.history.push(value.path)
  }

  onLetter(letter) {
    if (letter === 'Space') {
      this.props.addLetterToSearchTerm(' ')
    } else if (letter === 'Delete') {
      this.props.removeLetterFromSearchTerm()
    } else if (letter === 'Search') {
      const searchQuery = this.props.term.trim()
      if (searchQuery === '') {
        return false
      }
      this.props.clearResults()
      this.props.clearMenu('home:/widescreen_search')
      Object.keys(this.props.menus).forEach(menuid => {
        if (/search_summary$/.test(menuid)) this.props.clearMenu(menuid)
      })
      this.props.loadSearchNode(`/widescreen_search/?keywords=${searchQuery}`)
    } else {
      this.props.addLetterToSearchTerm(letter)
    }
    return true
  }

  render() {
    const { loadList, term, results, updateMenu, pathname, showModal, showSettings } = this.props;
    if (loadList) return (<PageLoading />)
    return (<SearchComponent
      term={term}
      focused={!(showModal || showSettings)}
      data={results.data}
      gridMenuId={`home:${pathname}`}
      menuid='searchspace'
      onFocusItem='topnav'
      entryFocus='search:atoz'
      onLetter={this.onLetter.bind(this)}
      updateMenu={updateMenu}
      contentKey={this.key}
    />)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer)
