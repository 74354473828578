import React from 'react'
import cx from 'classnames'
import AmazonMusicLogo from '../../assets/images/AmazonMusicLoadingLogoWhite.svg'
import './MainMenu.scss'

class MainMenu extends React.PureComponent {
  render() {
    const {menuItems, isFocused, focused, pathname, updateMenu, mid, onMouseOver = () => console.info('noop'), onEnter} = this.props;
    return (
      <div className='MainMenuContainer'>
        <div className='MainMenuLogo'>
          <img alt='amazon-music-logo' src={AmazonMusicLogo}/>
        </div>
        <ul className='MainMenu'>
          {menuItems.map((link, idx) => {
            let inFocus = isFocused(idx) || (!focused && link.path === pathname)
            return (
              <li key={idx}
                onMouseOver={() => {
                  updateMenu(mid, {index: idx})
                  onMouseOver()
                }}
                onClick={() => {
                  onEnter(link)
                }}
                className={cx({focused: inFocus}, "MainMenuItem")}
              >
                <span>{link.name}</span>
              </li>
            )
        })}
        </ul>
      </div>
    )
  }
}

export default MainMenu
