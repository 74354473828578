import React  from 'react'
import EmptyState from '../../assets/images/not-found.png'
import './EmptyPage.scss';

class EmptyPage extends React.Component {
  render () {
    return (
      <div className='emptyState'>
        <img className='emptyState-icon' alt='amazon-music-empty-recents' src={EmptyState}/>
        {this.props.message && <div className='message'>{this.props.message}</div>}
      </div>
    )
  }
}
export default EmptyPage
