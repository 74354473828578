const ERRORS = {
  unlink: {
    code: 101,
    ref: 'unlink_error',
    report: {
      brief: 'Unlink Error',
      explanation: 'Unable to unlink the device. Please try again.',
      options: []
    },
    source: 'Unlink Error'
  },
  soundbar: {
    ref: 'lgSoundbarError',
    report: {
      brief: 'Soundbar Issues',
      explanation: 'This TV May have issues playing music with the soundbar connected. Please try without soundbar.',
      options: [
        { action: 'refresh', label: 'Soundbar Unplugged', resume: false, uri: null },
        { action: 'replaceBack', label: 'Go back', resume: false, uri: null },
      ]
    },
    autoSkipOnError: false,
    source: 'Audio Expired'
  },
  audio_expired: {
    code: 301,
    ref: 'audioExpired',
    report: {
      brief: 'Expired Audio',
      explanation: 'This audio has expired. Please reload the audio to continue listening.',
      options: [{ action: 'reload', label: 'Reload', resume: false, uri: null }]
    },
    autoSkipOnError: false,
    source: 'Audio Expired'
  },
  explicit_language: {
    code: 303,
    ref: 'explicit_language',
    report: {
      brief: 'Explicit Language',
      explanation: 'Songs with explicit language are blocked.',
      options: []
    },
    source: 'Explicit Language'
  },
  exit_this_application: callback => ({
    ref: 'exit_this_application',
    report: {
      brief: 'Exit this Application',
      explanation: 'Are you sure you want to exit Amazon Music?',
      options: [
        { action: callback, label: 'Yes, exit', resume: false, uri: null },
        { action: null, label: 'No, cancel', resume: false, uri: null }
      ]
    },
    continuePlayback: true,
    source: 'Exit this Application'
  }),
  loading_timeout: (error = {}, ref = '') => ({
    code: 100,
    ref: ref || 'loading_timeout',
    report: {
      brief: 'Loading Timeout',
      explanation: 'There is an issue loading the current page. Please refresh the app or try again later.',
      options: [
        { action: 'refresh', label: 'Refresh', resume: false, uri: null },
        { action: 'replaceBack', label: 'Go back', resume: false, uri: null },
      ],
      ...error,
    },
    continuePlayback: true,
    source: 'Loading Timeout'
  }),
  upsell_banner: (error = {}) => ({
    ref: 'upsell_banner',
    autoSkipOnError: false,
    report: {
      brief: 'Welcome to Amazon Music Prime',
      explanation: 'Listen to this music and over 50 million more songs with Amazon Music Unlimited. Start a 30-day free trial at amzn.com/xamu',
      options: [{ action: null, label: 'OK', resume: false, uri: null }],
      ...error,
    },
    continuePlayback: true,
    source: 'Upsell Banner'
  }),
}

export default ERRORS
