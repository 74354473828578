import React from 'react'
import cx from 'classnames'
import { Trans } from 'react-i18next'
import createDebug from 'debug'
import { transcodeImg } from '../../lib/utils'
import {HomeMenuContext} from '../HomeMenu/HomeMenu'
import amazon_logo from './amazon_unlimited_logo.jpg'
import './HomeMenuCard.scss'

const debug = createDebug('app:HomeMenuCard');

const HomeMenuCard = ({ item: { itemLabel, image, subtitle, isExplicit, focusable }, focused, allowExplicit, updateIndex, idx, clickHandler }) => {
  const tryUnlimitedCard = (image && image.uri === 'amazon_unlimited_logo.jpg')
  return (
    <HomeMenuContext.Consumer>
      {({onFocus, mouseActive}) => (
        <div className={cx({ focused, isExplicit: (`${isExplicit}` === 'true' && !allowExplicit) }, 'HomeMenuCard')} onMouseOver={() => {
          debug(`Mouseover Card ${itemLabel} mouseActive: ${mouseActive} focusable: ${focusable}`)
          if(mouseActive ) {
            if(focusable) updateIndex(idx)
            setTimeout(() => onFocus(), 50) // Want this at end of update stack.
          }
        }} onClick={clickHandler}>
          {image ? <img src={tryUnlimitedCard ? amazon_logo : transcodeImg(image.uri)} height={75} width={75}
            alt={itemLabel} /> : null}
          <div className={cx('itemLabel', { 'itemLabel--subtitle': (subtitle) })}>
            {tryUnlimitedCard && <div className='goUnlimited'><Trans>Go Unlimited.</Trans></div>}
            <label className="title">{itemLabel}</label>
            {subtitle && <label className="subtitle">{subtitle}</label>}
          </div>
        </div>
      )}
    </HomeMenuContext.Consumer>
  )
}

export default HomeMenuCard
