import { createStore, applyMiddleware, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import rootReducer from './modules'
import { activateMouse, deactivateMouse } from './modules/mouse'
import rootSaga from './sagas'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'

export const history = createBrowserHistory()

const initialState = {}
const enhancers = []
const sagaMiddleware = createSagaMiddleware()
const middleware = [
  routerMiddleware(history), sagaMiddleware, thunk
]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
)
sagaMiddleware.run(rootSaga)

document.addEventListener('mousemove', () => {
  const { mouse: { mouseActive } } = store.getState()
  if (!mouseActive) { // Lets check so not to swamp with too many updates
    store.dispatch(activateMouse())
  }
})
document.addEventListener('keydown', () => {
  const { mouse: { mouseActive } } = store.getState()
  if(mouseActive) {
    store.dispatch(deactivateMouse())
  }
})
export default store
